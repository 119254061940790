import React from 'react';
// import 'antd/dist/antd.css';
import '@styles/components/formItem.scss';

interface FormItemProps {
  label?: string;
  className?: string;
  children?: JSX.Element;
  required?: boolean;
}

function FormItem(props: FormItemProps) {
  const { label, children, className, required } = props;
  const requiredMarker = required ? (
    <span className="required-marker">*</span>
  ) : null;
  return (
    <div className={`form-item-wrapper ${className}`}>
      <div className="form-label-wrapper">
        <label className="form-label" htmlFor="">
          {label}
        </label>
        {requiredMarker}
      </div>
      <div className="form-item-content">{children}</div>
    </div>
  );
}

export default FormItem;
