import { axios as axiosService } from './axios.service';

export const getIdamTokenRequest = (
  code: string,
): Promise<{
  access_token: string;
  id_token: string;
  expires_in: number;
} | null> => {
  if (!code || !process.env.REACT_APP_token_endpoint) {
    return Promise.resolve(null);
  }

  const data = `code=${code}&client_id=${process.env.REACT_APP_client_app_id}&client_secret=${process.env.REACT_APP_client_app_secret}&redirect_uri=${process.env.REACT_APP_app_url}&grant_type=authorization_code`;

  const options = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  return axiosService.post(process.env.REACT_APP_token_endpoint, data, options);
};

export const getTokenFromCode = async (code: string): Promise<any> => {
  try {
    const res = await axiosService.get(
      `/auth/v1/loginToken/${code}&redirect=${process.env.REACT_APP_app_url}`,
    );
    return res;
  } catch (ex) {
    // throw new Error(ex);
  }
};

export const getUserInfo = async (): Promise<any> => {
  try {
    const token = window.localStorage.getItem('accessToken');
    if (!token) {
      return null;
    }
    const res = await axiosService.get(`/auth/v1/validateLoginToken`);
    return res;
  } catch (ex) {
    // throw new Error(ex);
  }
};

export const logoutRequest = (): Promise<any> =>
  axiosService.get(`/auth/v1/logout`);

export const register = (data: any): Promise<any> =>
  axiosService.post(
    `${process.env.REACT_APP_DD_service_url}/auth/registration`,
    { ...data },
  );

export const getMAToken = (): Promise<any> =>
  axiosService.get(`/api/magateway/token`);

export const asyncTsaClientInfo = (
  info: {
    userEmail: string;
    userName: string;
  },
  token: string,
): Promise<any> =>
  axiosService.post(
    `${process.env.REACT_APP_permission_service_url}/api/user/edit`,
    info,
    { headers: { authorization: token } },
  );

export const requestAccess = (
  userInfo: any,
  requestApp: string,
  reason: string,
): Promise<any> =>
  axiosService.post(
    `/api/v2/requestAccess`,
    Object.keys(userInfo)
      .filter((k) => ['uid', 'name', 'email'].indexOf(k) !== -1)
      .reduce((acc, k) => Object.assign(acc, { [k]: userInfo[k] }), {
        app: requestApp,
        requestReason: reason,
      }),
  );

export const getPrmssns = (): Promise<any> =>
  axiosService.get(`/api/v2/getPermAndPendingAccessInfo`);

export const postUserTimeZoneInfo = (email: string): Promise<any> =>
  axiosService.post('/api/v2/userLoginInfo', {
    email,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

export const getAllProjectCount = (): Promise<any> => {
  return axiosService.get('/api/v2/getToolsProjectInfo');
  // return Promise.resolve({"gateway":[],"tsa":['605'],"sa":[]});
};

export const getAcceptTermsRequest = async (uid: any, data?: boolean) => {
  return axiosService.get(
    `${process.env.REACT_APP_DD_service_url}/auth/acceptTermsOfUse/${uid}`,
    { headers: { authorization: window.localStorage.getItem('accessToken'), refreshToken: window.localStorage.getItem('refreshToken') } },
  );
};

export const setAcceptTermsRequest = async (uid: any) => {
  return axiosService.post(
    `${process.env.REACT_APP_DD_service_url}/auth/acceptTermsOfUse/${uid}`,
    { data: 'true' },
    {
      headers: { authorization: window.localStorage.getItem('accessToken'), refreshToken: window.localStorage.getItem('refreshToken') },
    },
  );
};

export const getTheme = () => {
  return axiosService.get('/public/v1/config/theme');
};
