import { getTsaRequest, getTsaDependencyRequest } from '@services';
import { createActions } from 'redux-actions';
import { TsaActionsEnum } from '../types';
import { ProjectEntanglement } from '@models';

const tsaActions = createActions(
  {},
  TsaActionsEnum.GET_TSA,
  TsaActionsEnum.CHANGE_TSA,
  TsaActionsEnum.CHANGE_TSA_OPERATE_LOADING,
  TsaActionsEnum.GET_TSA_DEPENDENCY,
);

const getTsa = (projectId: number): any =>
  tsaActions.getTsa(getTsaRequest(projectId));

const changeTsa = (entanglements: ProjectEntanglement[]): any =>
  tsaActions.changeTsa(entanglements);

const changeTsaOperateLoading = (loading: boolean): any =>
  tsaActions.changeTsaOperateLoading(loading);

const getTsaDependency = (projectId: number, plan: number): any =>
  tsaActions.getTsaDependency(getTsaDependencyRequest(projectId, plan));

export const tsaAction = {
  getTsa,
  changeTsa,
  changeTsaOperateLoading,
  getTsaDependency,
};
