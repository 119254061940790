import React, { memo, useState, useEffect, useMemo } from 'react';
import { AutoComplete, Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import '@styles/components/selectDropdownSearch.scss';
interface SelectPropsInterface {
  options?: any[];
  defaultOptions?: boolean;
  value?: any;
  name?: string;
  rules?: any;
  maxLength?: number;
  defaultSelected?: any;
  onChange?: (value: string) => void;
  setIsAddFunction?: Function;
  setSelectProjectId?: Function;
  setSelectFunctionName?: Function;
  setSelectCustomizedfield?: Function;
  setFieldDisabled?: Function;
  setFieldType?: Function;
  setAddFieldId?: Function;
}

const SelectDropdownSearch = (props: SelectPropsInterface) => {
  const {
    options = [],
    setIsAddFunction,
    name = '',
    rules = {},
    maxLength = 0,
    setSelectProjectId,
    setSelectFunctionName,
    setFieldDisabled,
    setFieldType,
    setAddFieldId,
  } = props;
  const methods = useFormContext();
  const _options: any = useMemo(() => [], []);
  const [children, setChildren] = useState<any>([]);
  const { control, formState, errors } = methods || {};

  const errorMessage = errors ? errors[name]?.message : '';
  const handleSearch = (val: any) => {
    setIsAddFunction &&
      setIsAddFunction(
        children.some(
          (s: any) =>
            s.value && s.value.toUpperCase() === val.trim().toUpperCase(),
        ),
      );
    const filterList = children.filter(
      (d: any) => d.value && d.value.toUpperCase() === val.trim().toUpperCase(),
    );
    setSelectFunctionName &&
      setSelectFunctionName(
        (filterList[0] && filterList[0].value) || val.trim(),
      );
    setSelectProjectId &&
      setSelectProjectId(...filterList.map((d: any) => d.projectid));
    setAddFieldId && setAddFieldId(...filterList.map((d: any) => d.projectid));
    setFieldDisabled && setFieldDisabled(false);
    setFieldType && setFieldType('text');
    if (val === '') {
      setIsAddFunction && setIsAddFunction(true);
    }
    if (name === 'fieldName') {
      const optionObj = _options.find((f: any) => val === f.value) || {};
      setTimeout(() => {
        methods.setValue('fieldType', optionObj.type);
        methods.setValue('content', optionObj.content);
      }, 0);
      setFieldDisabled && setFieldDisabled(val === optionObj.value);
    }
  };

  const handleSelect = (val: any, option: any) => {
    methods.setValue(name, val);
    if (name === 'fieldName') {
      setTimeout(() => {
        methods.setValue('fieldType', option.type);
        methods.setValue('content', option.content);
      }, 0);
      setFieldDisabled && setFieldDisabled(true);
      setFieldType && setFieldType(option.type);
      setAddFieldId && setAddFieldId(option.projectid);
    }
    setSelectProjectId && setSelectProjectId(option.projectid);
    setSelectFunctionName && setSelectFunctionName(val);
    setIsAddFunction &&
      setIsAddFunction(children.some((s: any) => s.value === val.trim()));
  };

  useEffect(() => {
    options.forEach((item: any, index: number) => {
      _options.push({
        key: index,
        value: item.value,
        projectid: item.id,
        ...item,
      });
      setChildren(_options.filter((v: any) => v.value));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      setIsAddFunction && setIsAddFunction(true);
      setSelectProjectId && setSelectProjectId(0);
      setSelectFunctionName && setSelectFunctionName('');
      setFieldDisabled && setFieldDisabled(false);
      setAddFieldId && setAddFieldId(0);
    },
    [
      setIsAddFunction,
      setSelectProjectId,
      setSelectFunctionName,
      setFieldDisabled,
      setAddFieldId,
    ],
  );
  const autoComplete = (
    <AutoComplete
      options={children}
      onSearch={handleSearch}
      getPopupContainer={(): any => document.querySelector('.autoComplete-box')}
      filterOption={(inputValue, option) =>
        option?.value &&
        option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    >
      <Input maxLength={maxLength} />
    </AutoComplete>
  );

  const controllerRender = () => (
    <div className="autoComplete-box">
      <Controller
        as={autoComplete}
        control={control}
        name={name || ''}
        rules={rules || {}}
        onSelect={handleSelect}
        onSearch={handleSearch}
        onBlur={([value]) => {
          methods.setValue(
            name,
            value.target.value && value.target.value.trim(),
          );
        }}
        onChange={([value]) => {
          setSelectFunctionName && setSelectFunctionName(value);
          return value;
        }}
      />
      <p className="error-message">
        {formState && !formState.isSubmitted ? '' : errorMessage}
      </p>
    </div>
  );
  return <>{name && methods ? controllerRender() : autoComplete}</>;
};

export default memo(SelectDropdownSearch);
