import React, { useState, useRef, memo, useEffect } from 'react';
import { TreeSelect, Divider, Input, Checkbox } from 'antd';
import '@styles/components/selectCheckbox.scss';
import { selectCheckOption } from '@models';

interface SelectCheckboxPropsInterface {
  options: selectCheckOption[];
  selectedOption?: string[];
  onChange?: any;
  notCaseSensitive?: boolean;
  disabled?: boolean;
}

const SelectCheckbox = (props: SelectCheckboxPropsInterface) => {
  const {
    options,
    selectedOption,
    onChange,
    notCaseSensitive = false,
    disabled = false,
  } = props;
  const InputRef: any = useRef<Input>(null);
  const TreeSelectRef: any = useRef<any>(null);
  const [data, setData] = useState<any>([...options]);
  const [selectedData, setSelectedData] = useState(selectedOption || []);
  const [inputVisible, setInputVisible] = useState(false);
  const [values, setValue] = useState<any>('');
  const [hideMulSelectKey, setHideMulSelectKey] = useState<any>(0);

  useEffect(() => {
    setSelectedData(selectedOption || []);
    setData([...options]);
  }, [options, selectedOption]);

  const hideMulyipleBoxAfterSelected = async () => {
    await setInputVisible(false);
    TreeSelectRef.current && TreeSelectRef.current.blur();
  };

  useEffect(() => {
    // 将多选框隐藏
    hideMulyipleBoxAfterSelected();
  }, [hideMulSelectKey]);

  const onOtherCheck = async (e: {
    target: { checked: React.SetStateAction<boolean> };
  }) => {
    setInputVisible(e.target.checked);
    (await InputRef) &&
      InputRef.current &&
      (e.target.checked ? InputRef.current.focus() : InputRef.current.blur());
  };

  const onInputFocus = () => {
    setInputVisible(true);
  };

  const onInputKeyDown = (event: any) => {
    event.stopPropagation && event.stopPropagation();
  };

  const onInputChange = (event: any) => {
    event.stopPropagation && event.stopPropagation();
    setValue(event.target.value);
  };

  const addItem = () => {
    const value: string = values.trim();
    if (!value) {
      return;
    }
    setValue('');
    const existingData = data.find((item: selectCheckOption) => {
      if (notCaseSensitive) {
        return item.value.toLowerCase() === value.toLowerCase();
      }
      return item.value === value;
    });
    const existingSelected = selectedData.find((item: string) => {
      if (notCaseSensitive) {
        return item.toLowerCase() === value.toLowerCase();
      }
      return item === value;
    });
    if (existingData && existingSelected) {
      return;
    }
    if (existingData && !existingSelected) {
      setSelectedData([...selectedData, existingData.value]);
      onChange && onChange([...selectedData, existingData.value]);
    }
    if (!existingData && !existingSelected) {
      setData([...data, { title: value, value }]);
      setSelectedData([...selectedData, value]);
      onChange && onChange([...selectedData, value]);
    }
    setInputVisible(true);
    setHideMulSelectKey(hideMulSelectKey + 1);
  };

  const handleChange = (value: string[]) => {
    setSelectedData(value);
    setHideMulSelectKey(hideMulSelectKey + 1);
    onChange && onChange(value);
  };

  const dropdownInputRender = (menu: any) => (
    <div className="dropdown">
      <div>{menu}</div>
      <Checkbox checked={inputVisible} onChange={onOtherCheck}>
        Other
      </Checkbox>
      {inputVisible && (
        <div>
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              ref={InputRef}
              style={{ flex: 'auto' }}
              value={values}
              onFocus={onInputFocus}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              onPressEnter={addItem}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <TreeSelect
      key={hideMulSelectKey}
      ref={TreeSelectRef}
      className="multiple-select"
      value={selectedData}
      treeData={data}
      treeCheckable={true}
      showSearch={false}
      disabled={disabled}
      treeNodeLabelProp="title"
      onSelect={() => setHideMulSelectKey(hideMulSelectKey + 1)}
      onChange={handleChange}
      dropdownRender={dropdownInputRender}
      onDropdownVisibleChange={() => setInputVisible(false)}
      getPopupContainer={(): any => document.querySelector('.ant-drawer-body')}
    />
  );
};

export default memo(SelectCheckbox);
