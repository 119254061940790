import { axios as axiosService } from './axios.service';
import {
  SectorInfo,
  ProjectInput,
  ProjectInfo,
  ProjectfunctionStatus,
  CustomizedFieldModel,
  ManageFieldModel,
} from '@models';

interface UpdateProjectInput extends ProjectInput {
  projectId: string;
}

export const getSectorInfoRequest = (): Promise<SectorInfo[]> =>
  axiosService.get('/api/sector/list');

export const getClientInfoRequest = (projectId: any): Promise<any> =>
  axiosService.get('/api/client/list', { params: { projectId } });

export const createProjectRequest = (
  projectInput: ProjectInput,
): Promise<ProjectInfo> =>
  axiosService.post('/api/project/create', projectInput);

export const getProjectsInfoRequest = (): Promise<ProjectInfo[]> =>
  axiosService.get('/api/project/list');

export const getProjectFunctionStatusRequest = (
  projectId: number,
): Promise<ProjectfunctionStatus> =>
  axiosService.get('/api/project/status', { params: { projectId } });

export const getProjectSummary = (projectId: number): Promise<any> =>
  axiosService.get('/api/project/summary', { params: { projectId } });

export const updateProjectRequest = (
  projectInput: UpdateProjectInput,
): Promise<any> => axiosService.post('/api/project/edit', projectInput);

export const saveClientRequest = (
  projectId: any,
  clients?: any,
): Promise<any> =>
  axiosService.post('/api/client/save', { projectId, clients });

export const getPwCUsers = (name: string): Promise<any> =>
  axiosService.get('/api/user/list', { params: { name } });

export const getUserInfoByGuidRequest = (guid: string): Promise<any> =>
  axiosService.get('/api/user/one', { params: { guid } });

export const getProjectOverviewChartsRequest = (
  projectId: number,
): Promise<any> =>
  axiosService.get('/api/overview/projectOverview', { params: { projectId } });

export const getOverviewExportAsExcel = (projectId: number): Promise<Blob> =>
  axiosService.get('/api/project/exportStatus?', {
    params: { projectId },
    responseType: 'blob',
  });

export const createCustomizedfield = (
  customizedfield: CustomizedFieldModel,
): Promise<any> =>
  axiosService.post('/api/customizedfield/create', customizedfield);

export const getCustomizedFieldModelRequest = (
  projectId: number,
  entanglementId?: number,
): Promise<CustomizedFieldModel> =>
  axiosService.get('/api/customizedfield/list', {
    params: { projectId, entanglementId },
  });

export const getProjectFieldListRequest = (
  projectId: number,
): Promise<ManageFieldModel[]> =>
  axiosService.get('/api/projectField/list', { params: { projectId } });

export const saveProjectFieldListRequest = (
  data: ManageFieldModel[],
): Promise<any> => axiosService.post('/api/projectField/apply', data);
