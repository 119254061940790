import React, { useState, memo, useEffect, useRef } from 'react';

import { Select } from 'antd';
import linkIcon from '@assets/imgs/tsaIcon/link.png';
import '@styles/components/selectorMul.scss';
const { Option } = Select;
const InputLink = (props: any) => {
  const mulSelectRef: any = useRef<any>(null);
  const { linkListValue = '', form, name } = props;
  const [children, setChildren] = useState<any>();
  const [linkList, setLinkList] = useState<any>([]);

  const handleChange = (val: any) => {
    let hasError = false;
    val.forEach((item: string, i: number) => {
      if (
        !item ||
        !item.trim() ||
        (item && item.trim() && /[^\w\s[(][)]]/g.test(item.trim()))
      ) {
        hasError = true;
        form.setFields([{ name, errors: ['has error'] }]);
      }
    });
    if (!hasError) {
      form.setFields([
        {
          name,
          errors: null,
          linkList: val,
          value: val.toString(),
        },
      ]);
      setLinkList(val);
    }
    setTimeout(() => {
      mulSelectRef.current.blur();
    }, 0);
  };

  useEffect(() => {
    const linkListStr =
      (linkListValue && linkListValue.replace(/\n|\s/g, '')) || '';
    setLinkList(linkListStr.split(',').filter((v: any) => v) || []);
  }, [linkListValue]);

  useEffect(() => {
    const _options: any = [];
    linkList.forEach((item: any, index: number) => {
      const _link =
        item.trim().indexOf('http://') === 0 ||
        item.trim().indexOf('https://') === 0
          ? item
          : `http://${item}`;
      _options.push(
        <Option key={index} value={item}>
          <>
            <img src={linkIcon} alt="linkIcon" style={{ margin: '0 10px' }} />
            <a
              href={_link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#fff' }}
            >
              {item}
            </a>
          </>
        </Option>,
      );

      setChildren(_options);
    });
    return () => {
      setChildren([]);
    };
  }, [linkList]);

  return (
    <Select
      ref={mulSelectRef}
      className="multiple-select"
      mode="tags"
      style={{ width: '100%' }}
      onChange={handleChange}
      value={linkList || []}
      tokenSeparators={[',']}
      disabled={props.disabled || false}
      optionFilterProp="key"
      open={false}
      getPopupContainer={(): any => document.querySelector('.ant-drawer-body')}
    >
      {children}
    </Select>
  );
};

export default memo(InputLink);
