import React, { useState, memo } from 'react';
import { Select, Spin } from 'antd';
import '@styles/components/selectSearch.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { debounce, uniqBy } from 'lodash';
import { getPwCUsers } from '@services';

const { Option } = Select;

interface SelectCheckboxPropsInterface {
  name?: string;
  rules?: any;
  style?: {};
  listOpen?: boolean;
  placeholder?: string;
  valueKey?: 'selected' | 'value';
  selected?: any;
  value?: any;
  onChange?: () => void;
  onDropdownVisibleChange?: (value: any) => void;
  container?: string;
}
const SelectSearch = (props: SelectCheckboxPropsInterface) => {
  const { name = '', rules, placeholder } = props;
  const [data, setData] = useState<any>([]);
  const [lastFetchId, setLastFetchId] = useState(0);
  const [fetching, setFetching] = useState(false);
  const methods = useFormContext();
  const { control, errors, formState, getValues, reset } = methods || {};
  const errorMessage = errors ? errors[name]?.message : '';

  const getCurrentValue = () => {
    const currentValues = getValues();
    return currentValues[name];
  };

  const fetchUser = debounce((value: any) => {
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setData([]);
    value && setFetching(true);
    value &&
      getPwCUsers(value).then((res) => {
        if (fetchId !== lastFetchId) {
          return;
        }
        setData(res);
        setFetching(false);
      });
  }, 800);

  const handleChange = (event: any) => {
    setData([]);
    setFetching(false);
    const keys: string[] =
      event[0] && event[0].map((v: any) => v.key || v.value);
    let valueContainer = [...(getCurrentValue() || []), ...event[1]].filter(
      (v) => (v.value || v.key) && keys.includes(v.value || v.key),
    );
    valueContainer = uniqBy(valueContainer, 'key');

    const userValue = valueContainer.map((user: any) => {
      const userItem =
        user.info && typeof user.info === 'string'
          ? JSON.parse(user.info)
          : user.info;
      userItem.userName = userItem.fullName || userItem.userName;
      userItem.jobTitle = userItem.title || userItem.jobTitle;
      userItem.userEmail = userItem.email || userItem.userEmail;
      switch (name) {
        case 'partners': {
          userItem.isPartner = true;
          break;
        }
        case 'directors': {
          userItem.isDirector = true;
          break;
        }
        case 'members': {
          userItem.isMember = true;
          break;
        }
        default: {
          break;
        }
      }
      user.info = JSON.stringify(userItem);
      return user;
    });

    if (userValue) {
      if (name && reset) {
        reset({
          [name]: userValue.length ? userValue : undefined,
        });
      }
    }
  };

  const select = (
    <Select
      mode="multiple"
      className="multiple-select"
      value={getCurrentValue()}
      placeholder={placeholder}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={fetchUser}
      optionLabelProp="label"
      labelInValue={true}
      onChange={handleChange}
      defaultActiveFirstOption={false}
      dropdownClassName="dropdown-user-list"
      style={{ width: '100%' }}
      getPopupContainer={(): any => document.querySelector('.app-container')}
      onInputKeyDown={(event: any) => {
        if (event.key === 'Enter') {
          event.preventDefault && event.preventDefault();
        }
      }}
    >
      {data &&
        data.map((d: any) => (
          <Option
            key={d.guid}
            value={d.guid}
            label={d.fullName}
            info={JSON.stringify(d)}
          >
            <div className="dropdown-user-name">{d.fullName}</div>
            <div className="dropdown-user-detail">{d.email}</div>
            <div className="dropdown-user-detail">{d.title}</div>
            <div className="dropdown-user-detail">
              {d.country}: {d.department}
            </div>
          </Option>
        ))}
    </Select>
  );

  return (
    <div>
      {name && control ? (
        <>
          <Controller
            as={select}
            control={control}
            name={name || ''}
            rules={rules || {}}
            onChange={handleChange}
          />
          <p className="error-message">
            {formState && !formState.isSubmitted ? '' : errorMessage}
          </p>
        </>
      ) : (
        select
      )}
    </div>
  );
};

export default memo(SelectSearch);
