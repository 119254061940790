import { handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import {
  ProjectFunctionActionsEnum,
  ProjectFunctionReducerState,
} from '../types';
import { ProjectFunction } from '@models';

const defaultState: ProjectFunctionReducerState = {
  projectFunctions: [],
  resetProjectFunctions: [],
  templateFunctions: [],
  isFunctionsLoading: true,
  isFunctionsSaveLoading: false,
  isTemplateGetLoading: false,
  isSaveFunctionsLoading: false,
  isSaveFunctionsCompleted: false,
};

export default handleActions(
  {
    [ProjectFunctionActionsEnum.GET_PROJECT_FUNCTIONS_PENDING]: (
      state: ProjectFunctionReducerState,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.isFunctionsLoading = true;
      }),
    [ProjectFunctionActionsEnum.GET_PROJECT_FUNCTIONS_FULFILLED]: (
      state: ProjectFunctionReducerState,
      action: Action<ProjectFunction[]>,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.isFunctionsLoading = false;
        draft.projectFunctions = action.payload || [];
        draft.resetProjectFunctions = action.payload || [];
        draft.isSaveFunctionsLoading = false;
      }),
    [ProjectFunctionActionsEnum.CHANGE_PROJECT_FUNCTIONS]: (
      state: ProjectFunctionReducerState,
      action: Action<ProjectFunction[]>,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.projectFunctions = action.payload || [];
      }),
    [ProjectFunctionActionsEnum.RESET_PROJECT_FUNCTIONS]: (
      state: ProjectFunctionReducerState,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.projectFunctions = state.resetProjectFunctions;
      }),

    [ProjectFunctionActionsEnum.SAVE_PROJECT_FUNCTION_PENDING]: (
      state: ProjectFunctionReducerState,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.isSaveFunctionsLoading = true;
      }),

    [ProjectFunctionActionsEnum.SAVE_PROJECT_FUNCTION_FULFILLED]: (
      state: ProjectFunctionReducerState,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.isSaveFunctionsLoading = false;
        draft.isSaveFunctionsCompleted = !draft.isSaveFunctionsCompleted;
      }),

    [ProjectFunctionActionsEnum.GET_ALL_TEMPLATE_LIST_PENDING]: (
      state: ProjectFunctionReducerState,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.isTemplateGetLoading = true;
      }),
    [ProjectFunctionActionsEnum.GET_ALL_TEMPLATE_LIST_FULFILLED]: (
      state: ProjectFunctionReducerState,
      action: any, // Action<TemplateList[]>,
    ) =>
      produce(state, (draft: ProjectFunctionReducerState) => {
        draft.isTemplateGetLoading = false;
        draft.templateFunctions = action.payload || [];
      }),
  },
  defaultState,
);
