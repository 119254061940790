const { PopAlert } = require('appkit-react');

interface MessageProps {
  duration?: number;
  status?: 'success' | 'warning' | 'info' | 'dark' | 'error';
  message: string;
}

const showMessage = ({
  duration = 3000,
  status,
  message = 'success',
}: MessageProps) => {
  PopAlert({
    oneLineDisplay: true,
    duration,
    status,
    message,
    closeable: true,
    className: 'popup-message',
  });
  setTimeout(() => {
    try {
      const targetEle = document.querySelector('.a-alert-pop-wrapper');
      const removeEle = document.querySelector('.a-alert-pop-wrapper');
      if (targetEle && targetEle.parentNode && removeEle) {
        targetEle.parentNode.removeChild(removeEle);
      }
    } catch (e) {
      console.error(e);
    }
  }, duration);
};

export { showMessage };
