import { ReactNode } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Entanglement } from './entanglement.interface';

export interface RouteType {
  path?: string;
  label?: string;
  alias?: string;
  main?: any;
  icon?: string;
  exact?: boolean;
  render?: any;
  disabled?: boolean;
  routes?: RouteType[];
  showWhenHasId?: boolean;
  hideInMenu?: boolean;
  iconSize?: number;
  isMainRoute?: boolean;
  labelClassName?: string;
}

export interface RouteBreadCrumb {
  path: string;
  breadcrumbName: string;
  isMainRoute?: boolean;
}

export interface ButtonType {
  label: string;
  value: string;
  size: 'sm' | 'md' | 'lg' | 'xl';
  kind: 'primary' | 'secondary' | 'transparent';
  disabled?: boolean;
  visible?: boolean;
  icon?: string;
  position?: 'top' | 'bottom';
}

export interface Option {
  label: string;
  value: any;
  extra?: any;
}

export interface Label {
  labelName?: string;
  toolTip?: string;
  warning?: boolean;
  tooltipPosition?: string;
}

export interface DropdownOption extends Option {
  disabled?: boolean;
}

export interface ToggleConfig {
  switchOn?: boolean;
  preLabel: string;
  afterLabel: string;
}

export interface FieldItem {
  label: string;
  name?: string;
  required: boolean;
  type: string;
  options?: Array<DropdownOption>;
  toggleConfig?: ToggleConfig;
  position?: 'left' | 'right' | 'bottom';
  disabled?: boolean;
  shouldUppercase?: boolean;
  maxLength?: number;
  placeholder?: string;
  textProps?: string;
  switchFields?: boolean;
}

export interface delModalItem {
  label: string;
  name?: string;
}
export interface FieldItemWithLabel {
  label: Label;
  name?: string;
  required: boolean;
  type: string;
  defaultValue?: string | number;
  options?: Array<DropdownOption>;
  toggleConfig?: ToggleConfig;
  position?: string;
  disabled?: boolean;
  shouldUppercase?: boolean;
  maxLength?: number;
  rules?: any[];
  defaultOption?: string;
  defaultOptions?: [];
  subFields?: FieldItemWithLabel[];
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  showWithName?: string;
  showWithValues?: any[];
  colspan?: number;
  width?: string;
  hideDollarPrefix?: string;
}

export interface PanelContentType {
  id?: string;
  name: string;
  originData?: any;
}

export interface PanelType {
  header: string;
  originData?: any;
  content: PanelContentType[];
}

// TODO:
export interface CollapseContent {
  id: number | string;
  header?: ReactNode;
  children?: ReactNode[];
  collapseHeader?: {
    text: string | ReactNode;
    id?: string | number;
    selectable?: boolean;
    defaultChecked?: boolean;
    selectionType?: 'checkbox' | 'radio';
    isSingleSelection?: boolean;
  };
  collapseBody?: {
    parentId: number | string;
    contents: {
      value: number | string;
      label: string | ReactNode;
    }[];
    selectable?: boolean;
    defaultValue?: CheckboxValueType[];
    selectionType?: 'checkbox' | 'radio';
  };
}

export interface CollapseSelectItems {
  selectedParentIds: CheckboxValueType[];
  selectedChildIds: CheckboxValueType[];
  selectedGroup?: { [key: string]: string[] };
}

export interface StatisticsDetails {
  label?: string;
  value: number | string;
  key?: string;
  formatter?: (value: any) => any;
  isHideLabel?: boolean;
}

export interface StatisticsFields {
  label: string;
  details: StatisticsDetails[];
  afterLine: boolean;
  toolTip?: string;
}

export interface selectCheckOption {
  title: string;
  value: string;
}
export enum FunctionStatusValue {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export enum FunctionStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}

export enum DayOnePlan {
  TSA = 2,
  REVERSE_TSA = 3,
  SEPARATE = 1,
}

export const DayOnePlanText = {
  SEPARATE: 'Separate',
  TSA: 'TSA',
  REVERSE_TSA: 'Reverse TSA',
};

export enum EntangledValue {
  YES = 1,
  NO = 2,
  NOT_APPLICABLE = 3,
}

export const EntangledText = {
  YES: 'Yes',
  NO: 'No',
  NOT_APPLICABLE: 'Not Applicable',
};

export enum TransitionsPlanValue {
  Separate = 1,
  TSA = 2,
  RTSA = 3,
  TransitionNewCo = 4,
  NewCoBuildNew = 5,
  Outsource = 6,
  Discontinue = 7,
}

export interface MatchProps {
  params: { id: string };
  isExact: boolean;
  path: string;
  url: string;
}

export type BulkEidtColumnsProps = ColumnProps<any> & {
  disabled?: boolean;
  formType?: string;
  options?: any[];
  titleOrigin?: any;
  editable?: boolean;
  checked?: boolean;
  dollarPrefix?: boolean;
  disabledWithName?: string;
  disabledWithValues?: any[];
  isCustomField?: boolean;
  changeFunction?: (r: Entanglement, d: string, value: object) => any;
  formatter?: (value: any) => ReactNode;
  [key: string]: any;
};

export interface ReferenceFieldItem {
  label: ReactNode;
  dataIndex: string;
  position?: 'left' | 'right';
  formatter?: (value: any) => ReactNode;
  subFields?: ReferenceFieldItem[];
  isSubField?: boolean;
}

export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';
