import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Redirect } from 'react-router';
// import Login from '@pages/Login/Login';
import Register from '@pages/register';
import AppContainer from './AppContainer';
import { initRouter } from '@utils';
import { getTheme } from '@services';

const THEME_LIGHT = 'theme-light';

const lightModeHandler = (e: any) => {
  if (e.keyCode === 76 && e.ctrlKey) {
    console.log('switch to light mode');
    document.body.classList.add(THEME_LIGHT);
  }
};

const darkModeHandler = (e: any) => {
  if (e.keyCode === 68 && e.ctrlKey) {
    console.log('switch to dark mode');
    document.body.classList.remove(THEME_LIGHT);
  }
};
function Entry() {
  initRouter({
    match: null,
    location: null,
    history: null,
  });

  useEffect(() => {
    document.addEventListener('keydown', lightModeHandler);
    document.addEventListener('keydown', darkModeHandler);
    return () => {
      document.removeEventListener('keydown', lightModeHandler);
      document.removeEventListener('keydown', darkModeHandler);
    };
  }, []);

  useEffect(() => {
    getTheme().then((res) => {
      //@ts-ignore
      if (res?.config?.value === 'light') {
        document.body.classList.add(THEME_LIGHT);
      } else {
        document.body.classList.remove(THEME_LIGHT);
      }
    });
  }, []);

  return (
    <div className="app">
      <Router>
        <Switch>
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/registration" component={Register} />
          <Route path="/" component={AppContainer} />
          <Redirect path="*" to="/login" />
        </Switch>
      </Router>
    </div>
  );
}

export default Entry;
