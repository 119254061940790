import React from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from '@models';
import '@styles/pages/entanglements/entanglementsTable.scss';

function Tooltips(value: {} | null | undefined, placement?: TooltipPlacement) {
  return (
    <Tooltip
      overlayClassName="model-description-tooltip"
      placement={placement || 'bottom'}
      overlayStyle={{
        maxWidth: '40%',
      }}
      title={value}
    >
      <div className="model-description-cell">{value}</div>
    </Tooltip>
  );
}

export default Tooltips;
