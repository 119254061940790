import React, { memo, forwardRef } from 'react';

import { Checkbox } from 'antd';

const CheckBox = (props: any, ref: any) => {
  const { options = [], defaultOptions = [], form, name, value = [] } = props;

  const handleChange = (e: any) => {
    form && form.setFieldsValue({ [name]: e });

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <>
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={handleChange}
        disabled={props.disabled || false}
        defaultValue={defaultOptions}
        value={value}
        options={options}
      />
    </>
  );
};

export default memo(forwardRef(CheckBox));
