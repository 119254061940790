import React, {useEffect, useState} from 'react';
import {Button, Input, Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import {RouteComponentProps} from "react-router";
import '@styles/pages/errorPage.scss';
import useRequestAccessService from "@src/hooks/useRequestAccessService";
import {useSelector} from "react-redux";
import {RootStoreType} from "@reducer";
import {getValueFromSearchParam} from "@utils";
import lockImg from '../../../public/lock.png';

const ValidApps = ['Gateway', 'TSA', 'Standalone'];

function Error(props: RouteComponentProps) {
    const userInfo = useSelector((store:RootStoreType) => store.login.userInfo);
    const { requestApp } = getValueFromSearchParam('requestApp');

    const { allowedApps, pendingApps, fetchPermissions, sendRequest, loading } = useRequestAccessService();
    const [reason, setReason] = useState('');
    const onSubmit = () => {
        sendRequest(userInfo, requestApp, reason).then(() => {
            fetchPermissions();
        })
    }

    useEffect(() => {
        if(userInfo.isInternal) {
            fetchPermissions();
        }
    }, [userInfo]);

    const pending = pendingApps.includes(requestApp!);

    return (
        <div className="error-page-wrapper">
            <h1 className="title">
                {/* <span className="appkiticon icon-alert-fill error"/> */}
                <img src={lockImg} alt="" width={30} height={30} />
                Request Access
            </h1>
            {/* <p className="message">Sorry. You don’t have the permission to open the page.</p> */}
            <p className='message'>
                Ask for access to M&A Gateway, or contact our support team via email for any questions - <a
                    href="mailto:us_dt_solution_innovation@pwc.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    us_dt_solution_innovation@pwc.com
                </a>
            </p>
            {userInfo?.isInternal && ValidApps.includes(requestApp!) ? <Spin spinning={loading} indicator={<LoadingOutlined />}>
                {/* <Input.TextArea className="reason" onChange={(e)=> setReason(e.target.value)} hidden={pending} /> */}
                <Button
                    className="request-access-btn"
                    type="primary"
                    size="large"
                    disabled={pending}
                    onClick={onSubmit}
                >
                    {pending
                        ? 'Requested'
                        : 'Request Access'}
                </Button>
            </Spin> : null}
        </div>
    );
}

export default Error;