import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
// import { Dispatch } from 'redux';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { RootStoreType } from '@reducer';
import { Logo } from '@components';
import { RouteType } from '@models';
import { loginAction } from '@action';
import { Tooltip, Avatar } from 'antd';
// import WelcomePage from '@pages/Project/ProjectList/ProjectWelcome';
import '@styles/components/avatar.scss';

const mapStateToProps = (props: RootStoreType) => {
  const { login, common } = props;
  return {
    userInfo: login.userInfo,
    breadcrumbData: common.breadcrumb,
  };
};

const mapDispatchToProps = () => ({
  logout: loginAction.logout(),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface AppHeaderPropsInterface
  extends ConnectedProps<typeof connector>,
    RouteComponentProps {
  //from parent comp
  routes?: Array<RouteType>;
  basePath?: any;
  label?: string;
}

function AppHeader(props: AppHeaderPropsInterface) {
  const dispatch = useDispatch();
  const { userInfo, logout } = props;
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const {
    userInfo: { family_name = 'User', given_name = 'Name' },
  } = props;
  const nameLabel = (given_name[0] + family_name[0]).toUpperCase();
  // console.log(userInfo);

  // temporarily solution
  // const newBreadcrumbData = [breadcrumbData[0]];
  // const lastBreadcrumbData: any = breadcrumbData[breadcrumbData.length - 1];
  // if (
  //   currentProjectInfo &&
  //   lastBreadcrumbData &&
  //   !lastBreadcrumbData.isMainRoute
  // ) {
  //   const newItem = {
  //     path: `/overview/${currentProjectInfo.id}`,
  //     breadcrumbName: currentProjectInfo.name,
  //   };
  //   newBreadcrumbData.push(newItem);
  // }
  // if (breadcrumbData.length > 1) {
  //   newBreadcrumbData.push(lastBreadcrumbData);
  // }

  const handleSignOut = () => {
    logout(dispatch);
  };

  // const handleWelcomeClose = () => {
  //   setWelcomeVisible(false);
  // };

  const logoutTooltip = (
    <div className="avatar-menu-wrapper">
      <div className="user-contain flex-row-start">
        <Avatar className="user-contain-avatar" size="default" shape="circle">
          {nameLabel}
        </Avatar>
        <div className="user-info">
          <p className="user-info-name">
            {`${userInfo.given_name} ${userInfo.family_name}`}
          </p>
          <p className="user-info-email">{userInfo.email}</p>
        </div>
      </div>
      {/* <div className="divide-line" /> */}
      <div className="logout-contain">
        <a onClick={handleSignOut}>SIGN OUT</a>
      </div>
    </div>
  );

  return (
    <header className="app-header">
      <Logo />
      <div className="title">
        {/* <BreadCrumb data={newBreadcrumbData} /> */}
        M&A Gateway
      </div>

      {/* <Popover
        title={null}
        visible={welcomeVisible}
        onVisibleChange={setWelcomeVisible}
        content={
          <div className="project-welcome welcome-menu">
            {/* <WelcomePage onClose={handleWelcomeClose} /> 
          </div>
        }
        overlayClassName="welcome-menu-wrapper"
        placement="bottomRight"
        arrowPointAtCenter
        trigger="click"
        color="#1A1A1A"
      >
        <span className="appkiticon icon-help-question-outline question-icon" />
      </Popover> */}
      {userInfo.given_name ? (
        <Tooltip
          className="avatar-menu-wrapper"
          placement="bottomLeft"
          title={logoutTooltip}
          arrowPointAtCenter
          trigger="click"
          overlayClassName="avatar-menu"
          destroyTooltipOnHide
          visible={tooltipVisible}
          onVisibleChange={setTooltipVisible}
        >
          <Avatar
            className="avatar avatar-wrapper"
            size="default"
            shape="circle"
          >
            {nameLabel}
          </Avatar>
        </Tooltip>
      ) : null}
    </header>
  );
}

export default connector(withRouter(AppHeader));
