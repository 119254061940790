import React from 'react';

export const DisclimerContent = () => (
  <>
    <h2>
      <strong>Project Disclaimer</strong>
    </h2>
    <p>
      This information and PricewaterhouseCoopers LLP’s (“PwC”) services
      (collectively, “Information”) are confidential and access, use and
      distribution are restricted. If you are not PwC’s client or otherwise
      authorized by PwC and its client, you may not access or use the
      Information.
    </p>

    <p>
      PwC performed and prepared the Information at client’s direction and
      exclusively for client’s sole benefit and use pursuant to its client
      agreement. THE INFORMATION MAY NOT BE RELIED UPON BY ANY PERSON OR ENTITY
      OTHER THAN PWC’S CLIENT. PWC MAKES NO REPRESENTATIONS OR WARRANTIES
      REGARDING THE INFORMATION AND EXPRESSLY DISCLAIMS ANY CONTRACTUAL OR OTHER
      DUTY, RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY OTHER THAN ITS
      CLIENT.
    </p>
    <p>
      The Information was performed or prepared in accordance with the Standards
      for Consulting Services of the American Institute of Certified Public
      Accountants (“AICPA”) and, where applicable, the AICPA Standards for
      Reports on the Application of Accounting Principles or the AICPA
      Statements on Standards for Tax Services. The Information does not
      constitute legal or investment advice, broker dealer services, a fairness
      or solvency opinion, an estimate of value, an audit, an examination of any
      type, an accounting or tax opinion, or other attestation or review
      services in accordance with standards of the AICPA, the Public Company
      Accounting Oversight Board or any other professional or regulatory body.
      PwC provides no opinion or other form of assurance with respect to the
      Information. Client, in consultation with its independent accountants, is
      responsible for the presentation and preparation of its financial
      statements and related disclosures.
    </p>
    <p>
      The Information shall be maintained in strict confidence and may not be
      discussed with, distributed or otherwise disclosed to any third party, in
      whole or in part, without PwC’s prior written consent, nor may the
      Information be associated with, referred to or quoted in any way in any
      offering memorandum, prospectus, registration statement, public filing,
      loan or other agreement.
    </p>
    <p>
      The Information was not intended or written to be used, and it may not be
      used, for the purpose of avoiding U.S. federal, state or local tax
      penalties, or supporting the promotion or marketing of any transactions or
      matters addressed in the Information. Client has no obligation of
      confidentiality with respect to any information related to the tax
      structure or tax treatment of any transaction.
    </p>
    <p>
      Information provided by or through third parties is provided “as is”,
      without any representations or warranties by PwC or such third parties.
      PwC and such third parties disclaim any contractual or other duty,
      responsibility or liability to Client or any other person or entity that
      accesses such information.Any underlying prospective financial information
      (“PFI”) referred to in the Information was not prepared or developed by
      PwC and PwC has not restated any PFI or made assumptions or projections
      relating to PFI. While PwC may have performed sensitivity analyses on PFI
      and underlying assumptions, any tables aggregating PwC’s comments or
      observations of vulnerabilities and sensitivities do not represent
      restatements of or revisions to PFI; they are only a summary of PwC’s
      analyses to assist PwC’s client with its evaluation of PFI. PwC’s client
      is responsible for making its own decisions regarding PFI. As events and
      circumstances frequently do not occur as expected, there may be material
      differences between PFI and actual results. PwC disclaims responsibility
      and liability for PFI and any results achieved.
    </p>
    <br />
    <br />
  </>
);

const Disclaimer = () => (
  <div className="privacy-p">
    <DisclimerContent />
  </div>
);

export default Disclaimer;
