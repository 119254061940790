import { handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { EntanglementsActionsEnum, EntanglementReducerState } from '../types';
import { ProjectEntanglement } from '@models';

const defaultState: EntanglementReducerState = {
  projectEntanglements: [],
  isEntanglementsLoading: true,
  isEntanglementsOperateLoading: false,
};

export default handleActions(
  {
    [EntanglementsActionsEnum.GET_ENTANGLEMENTS_PENDING]: (
      state: EntanglementReducerState,
    ) =>
      produce(state, (draft: EntanglementReducerState) => {
        draft.isEntanglementsLoading = true;
      }),
    [EntanglementsActionsEnum.GET_ENTANGLEMENTS_FULFILLED]: (
      state: EntanglementReducerState,
      action: Action<ProjectEntanglement[]>,
    ) =>
      produce(state, (draft: EntanglementReducerState) => {
        draft.isEntanglementsLoading = false;
        draft.projectEntanglements = action.payload || [];
      }),
    [EntanglementsActionsEnum.CHANGE_ENTANGLEMENTS]: (
      state: EntanglementReducerState,
      action: Action<ProjectEntanglement[]>,
    ) =>
      produce(state, (draft: EntanglementReducerState) => {
        draft.projectEntanglements = action.payload || [];
      }),
    [EntanglementsActionsEnum.CHANGE_ENTANGLEMENTS_OPERATE_LOADING]: (
      state: EntanglementReducerState,
      action: Action<any>,
    ) =>
      produce(state, (draft: EntanglementReducerState) => {
        draft.isEntanglementsOperateLoading = action.payload || false;
      }),
  },
  defaultState,
);
