import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
  MutableRefObject,
} from 'react';
import ReactDom from 'react-dom';
import { ButtonType } from '@models';
import '@styles/components/modal.scss';

const { Button } = require('appkit-react');

type FunctionType = () => void;

interface ModalProps {
  title?: string | JSX.Element;
  footer?: ButtonType[];
  children?: JSX.Element;
  maskClosable?: boolean;
  hideTitle?: boolean;
  hideFooter?: boolean;
  width?: string;
  onOk?: FunctionType;
  onCancel?: FunctionType;
  isLoading?: boolean;
  isAllLoading?: boolean | any;
  changeConfig?: (val: any) => void;
  rootNode?: string;
  className?: string;
}

interface ModalButtonType extends ButtonType {
  onClick?: FunctionType;
}

const Modal = forwardRef(
  (props: ModalProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      title = '',
      children,
      footer = null,
      width = '800px',
      isLoading,
      isAllLoading,
      changeConfig,
      rootNode,
      className = '',
    } = props;

    const rootDom: MutableRefObject<any> = useRef(null);
    const modalRef: MutableRefObject<any> = useRef(null);
    const [isShow, toggleShow] = useState(false);
    const [activeAnimation, toggleActiveAnimation] = useState(false);

    const hideModal = useCallback(
      (e?: any) => {
        e && e.stopPropagation && e.stopPropagation();
        toggleActiveAnimation(false);

        setTimeout(() => {
          toggleShow(false);
        }, 280);
      },
      [toggleShow, toggleActiveAnimation],
    );

    const showModal = useCallback(() => {
      toggleShow(true);
      toggleActiveAnimation(true);
    }, [toggleShow, toggleActiveAnimation]);

    useEffect(() => {
      rootDom.current = document.querySelector(rootNode || '#root');

      return () => {
        rootDom.current = null;
      };
    }, [rootNode]);

    useImperativeHandle(
      ref,
      (): any => ({
        show(config: any) {
          showModal();
          changeConfig &&
            changeConfig({
              ...(config || {}),
              hideModal,
            });
        },
        hide: () => hideModal(),
      }),
      [changeConfig, hideModal, showModal],
    );

    if (!rootDom.current) {
      return null;
    }

    const handleOk = () => {
      props.onOk && props.onOk();
    };

    const handleCancel = () => {
      hideModal();
      props.onCancel && props.onCancel();
    };

    const handleSubmit = (button: ModalButtonType) => {
      if (button.onClick) {
        return button.onClick();
      }
      if (button.value === 'cancel') {
        return handleCancel();
      }
      if (
        button.value === 'save' ||
        button.value === 'ok' ||
        button.value === 'create'
      ) {
        return handleOk();
      }
      return null;
    };

    const actionButtons = (buttons: ModalButtonType[]) =>
      buttons.map((button: ModalButtonType) => (
        <Button
          className="btn-wrapper"
          key={button.value}
          kind={button.kind}
          size={button.size}
          disabled={button.disabled}
          isLoading={isLoading}
          onClick={() => handleSubmit(button)}
        >
          <span className="text-capitalize">{button.label}</span>
        </Button>
      ));

    return ReactDom.createPortal(
      <div
        ref={modalRef}
        className={`modal-wrapper ${className ? className : ''}`}
      >
        {isShow && (
          <div className="ant-modal-root">
            <div className="ant-modal-mask" />
            <div className="ant-modal-wrap ant-modal-centered" role="dialog">
              <div role="document" className="ant-modal">
                <div
                  className={`ant-modal-content ${
                    activeAnimation ? 'modal-show' : 'modal-hide'
                  }`}
                  style={{ width }}
                >
                  <button
                    type="button"
                    className="ant-modal-close"
                    onClick={handleCancel}
                  >
                    <span className="appkiticon icon-close-fill" />
                  </button>

                  {props.hideTitle ? null : (
                    <div className="ant-modal-header">
                      <div className="ant-modal-title">{title || ''}</div>
                    </div>
                  )}

                  {isAllLoading ? (
                    <div className="centerLoading">
                      <div className="a-loading a-primary a-m-10" />
                    </div>
                  ) : (
                    <div className="ant-modal-body">{children}</div>
                  )}

                  {props.hideFooter ? null : (
                    <div className="ant-modal-footer">
                      {footer && actionButtons(footer)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>,
      rootDom.current,
    );
  },
);

export default Modal;
