import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker as AntdDatePicker } from 'antd';
import moment, { Moment } from 'moment';
import '@styles/components/datePicker.scss';

interface DatePickerPropsInterface {
  name: string;
  rules?: any;
  placeholder?: string;
  defaultDate?: string;
  onChange?: (date: Moment | null) => void;
  dateFormat?: string;
}

function DatePicker(props: DatePickerPropsInterface) {
  const {
    name,
    rules,
    placeholder = '',
    defaultDate,
    dateFormat = 'MMM DD YYYY',
    onChange: propsOnChange,
  } = props;

  const methods = useFormContext();
  const { errors, formState } = methods;
  const errorMessage = errors[name]?.message;

  const defaultValue = defaultDate ? moment(defaultDate) : undefined;

  // NOTE: format, timezone
  function onChange(date: Moment | null, dateString: string): void {
    if (propsOnChange) {
      propsOnChange(date);
    }
  }

  function disabledDate(current: any) {
    return current && current < moment().year(moment().year() - 1);
  }

  const datepicker = (
    <AntdDatePicker
      size="large"
      placeholder={placeholder}
      format={dateFormat}
      defaultValue={defaultValue}
      onChange={onChange}
      disabledDate={disabledDate}
      getPopupContainer={(): any => document.querySelector('.app-container')}
    />
  );

  return (
    <div>
      {name && methods ? (
        <Controller
          as={datepicker}
          control={methods.control}
          name={name || ''}
          rules={rules || {}}
        />
      ) : (
        datepicker
      )}
      <p className="error-message">
        {formState && !formState.isSubmitted ? '' : errorMessage}
      </p>
    </div>
  );
}

export default DatePicker;
