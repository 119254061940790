import { CommonActionsEnum, CommonReducerState } from '../types';
import { handleActions } from 'redux-actions';
import produce from 'immer';

const defaultState: CommonReducerState = {
  currentProjectId: '',
  isLoading: false,
  breadcrumb: [],
};

export default handleActions(
  {
    [CommonActionsEnum.CHANGE_BREADCRUMB]: (state, action) =>
      produce(state, (draft: any) => {
        draft.breadcrumb = action.payload;
      }),

    [CommonActionsEnum.CHANGE_CURRENT_PROJECT_ID]: (state, action) =>
      produce(state, (draft: any) => {
        draft.currentProjectId = action.payload;
      }),
  },
  defaultState,
);
