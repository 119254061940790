import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import reducers, { RootStoreType } from './reducer';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['login'],
};

const persistedReducer = persistReducer<RootStoreType>(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  applyMiddleware(promise, thunk, logger),
);
export default store;
export const persistor = persistStore(store);
