import React from 'react';
import '@styles/components/loading.scss';

interface LoadingProps {
  isLoading?: boolean;
  tip?: string;
  isFullScreen?: boolean;
}

function Loading(props: LoadingProps) {
  const { isLoading = true, isFullScreen = true, tip = 'Loading' } = props;
  return !isLoading ? null : (
    <div className={isFullScreen ? 'loading-wrapper-fullscreen' : ''}>
      <div className="d-inline-block text-center loading-container">
        <div className="a-loading a-default" />

        <div className="a-mt-10 a-h6 a-apply-theme-font-default-white text-capitalize">
          {tip || ''}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Loading);
