import { handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { TransitionsActionsEnum, TransitionReducerState } from '../types';
import { ProjectEntanglement } from '@models';

const defaultState: TransitionReducerState = {
  projectEntanglements: [],
  isEntanglementsLoading: true,
  isEntanglementsOperateLoading: false,
};

export default handleActions(
  {
    [TransitionsActionsEnum.GET_TRANSITIONS_PENDING]: (
      state: TransitionReducerState,
    ) =>
      produce(state, (draft: TransitionReducerState) => {
        draft.isEntanglementsLoading = true;
      }),
    [TransitionsActionsEnum.GET_TRANSITIONS_FULFILLED]: (
      state: TransitionReducerState,
      action: Action<ProjectEntanglement[]>,
    ) =>
      produce(state, (draft: TransitionReducerState) => {
        draft.isEntanglementsLoading = false;
        draft.projectEntanglements = action.payload || [];
      }),
    [TransitionsActionsEnum.CHANGE_TRANSITIONS]: (
      state: TransitionReducerState,
      action: Action<ProjectEntanglement[]>,
    ) =>
      produce(state, (draft: TransitionReducerState) => {
        draft.projectEntanglements = action.payload || [];
      }),
    [TransitionsActionsEnum.CHANGE_TRANSITIONS_OPERATE_LOADING]: (
      state: TransitionReducerState,
      action: Action<any>,
    ) =>
      produce(state, (draft: TransitionReducerState) => {
        draft.isEntanglementsOperateLoading = action.payload || false;
      }),
  },
  defaultState,
);
