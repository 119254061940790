import React, { memo, forwardRef } from 'react';
/* eslint-disable no-new */
import { Cascader, Tooltip } from 'antd';
import '@styles/components/cascader.scss';

const CascaderBox = (props: any, ref: any) => {
  const {
    options = [],
    defaultValue = [],
    onBlur,
    onChange,
    onPopupVisibleChange,
  } = props;

  const dropdownRender = (menus: any) => (
    <Tooltip title={menus} className="tootip-menus">
      {menus}
    </Tooltip>
  );

  return (
    <div onBlur={onBlur}>
      <Cascader
        // ref={ref}
        className="cascader-box"
        expandTrigger="hover"
        options={options}
        allowClear={false}
        onChange={onChange}
        changeOnSelect
        onPopupVisibleChange={onPopupVisibleChange}
        defaultValue={defaultValue}
        dropdownRender={dropdownRender}
        disabled={props.disabled || false}
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
      />
    </div>
  );
};

export default memo(forwardRef(CascaderBox));
