// import React from 'react';
import { RouteType, RouteBreadCrumb } from '@models';
// import { Redirect } from 'react-router';
import each from 'lodash/each';
import compact from 'lodash/compact';

// import ProjectOverview from '@pages/Project/ProjectOverview/ProjectOverviewPlaceholder';
import PrivacyStatementPage from '@pages/PrivacyStatement/PrivacyStatement';
import LandingPage from '@src/pages/LandingPage';
import Login from '@src/pages/Login/Login';
import Error from "@pages/Error/Error";

const routerProps: { [name: string]: any } = {
  match: null,
  location: null,
  history: null,
};

export const routes: RouteType[] = [
  {
    showWhenHasId: false,
    path: 'privacy-statement',
    label: 'Privacy Statement',
    hideInMenu: true,
    icon: '',
    isMainRoute: true,
    main: PrivacyStatementPage,
  },
  {
    showWhenHasId: false,
    path: '/',
    label: 'landingpage',
    hideInMenu: true,
    icon: '',
    isMainRoute: true,
    main: LandingPage,
  },
  {
    showWhenHasId: false,
    path: 'login',
    label: 'login',
    hideInMenu: true,
    icon: '',
    isMainRoute: true,
    main: Login,
  },
  {
    showWhenHasId: false,
    path: 'error',
    label: 'login',
    hideInMenu: true,
    icon: '',
    isMainRoute: true,
    main: Error,
  },
];

export const initRouter = (props: any) => {
  if (props && !routerProps.history) {
    each(props, (value, name) => {
      routerProps[name] = value;
    });
  }
};

export const getRoutes = (
  isSelected: boolean,
  isAdmin: boolean,
): Array<RouteType> => {
  if (isAdmin) {
    routes.push({
      path: 'admin',
      label: 'admin',
    });
  }

  return routes;
};

function getBreadItems(data: Array<any>, root = '/'): RouteBreadCrumb[] {
  let breadCrumbs: RouteBreadCrumb[] = [];
  data.forEach((item) => {
    const {
      path: _path,
      label: breadcrumbName,
      routes: itemRoutes,
      isMainRoute,
    } = item;
    const path = (root + _path).split('/:')[0];

    if (!breadcrumbName) {
      return;
    }

    const result = {
      path,
      breadcrumbName,
      isMainRoute,
    };

    breadCrumbs.push(result);

    if (Array.isArray(itemRoutes) && itemRoutes.length > 0) {
      const itemRoutesBreadItems = getBreadItems(itemRoutes, path);
      breadCrumbs = breadCrumbs.concat(itemRoutesBreadItems || []);
    }
  });
  return breadCrumbs;
}

function getBreadData(): RouteBreadCrumb[] {
  const res: RouteBreadCrumb[] = getBreadItems(routes);

  // res.unshift({
  //   path: '/',
  //   breadcrumbName: 'TSA',
  // });
  // res.unshift({
  //   path: '/',
  //   breadcrumbName: 'M&A Gateway',
  // });
  return compact(res);
}

const breadCrumbMap = getBreadData();

export { breadCrumbMap };
