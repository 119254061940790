import React from 'react';
import { NavLink } from 'react-router-dom';

export const CookiesContent = () => (
  <>
    <h2>
      <strong>Cookie Statement</strong>
    </h2>
    <p>
      <span>
        This cookie information is relevant for visitors to M&amp;A Gateway.
      </span>{' '}
      <span>
        For more information about how we process your personal information
        collected through M&amp;A Gateway, please visit our M&amp;A Gateway{' '}
      </span>
      {/* <span
        className="internal-link"
        onClick={() => history?.replace('/privacy?policy')}
      >
        privacy statement
      </span> */}
      <NavLink to="/privacy-statement">statement</NavLink>
      <span>.</span>
    </p>
    <p>
      <span>
        Cookies are small text files that are placed on your device by the
        websites and sites that you visit. They are widely used in order to make
        websites and apps work, or work more efficiently, as well as to provide
        information to the owners of the site or app. The use of cookies and
        similar technologies is now standard for most websites and apps.&nbsp;
      </span>
    </p>
    <p>&nbsp;</p>
    <h3>
      <span>Managing cookies on your device</span>
    </h3>
    <p>
      <span>
        You can control and manage cookies using your browser settings as well
        as certain opt-out tools (see below). Please note that if you are
        accessing this site using multiple browsers or devices, you will need to
        manage cookies on each browser and device, as applicable. Please also
        note that removing or blocking cookies can impact your user experience
        and some functionality may no longer be available.
      </span>
    </p>
    <h3>
      <span>Using your browser to control cookies</span>
    </h3>
    <p>
      <span>
        Most browsers allow you to view, manage, delete and block cookies for a
        website or site. Be aware that if you delete all cookies then any
        preferences you have set will be lost, including the ability to opt-out
        from cookies as this function itself requires placement of an opt out
        cookie on your device.&nbsp; Guidance on how to control cookies for
        common browsers is linked below.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en">
        <strong>Google Chrome</strong>
      </a>
    </p>
    <p>
      <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
        <strong>Mozilla Firefox</strong>
      </a>
    </p>
    <p>
      <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
        <strong>MacOS Safari</strong>
      </a>
    </p>
    <p>
      <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
        <strong>Microsoft Internet Explorer</strong>
      </a>
    </p>
    <p>
      <a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies">
        <strong>Microsoft Edge</strong>
      </a>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        For information on additional browsers and device types please see{' '}
      </span>
      <a href="http://www.aboutcookies.org/">
        <strong>http://www.aboutcookies.org/</strong>
      </a>
      <span> or </span>
      <a href="http://www.cookiecentral.com/faq/">
        <strong>http://www.cookiecentral.com/faq/</strong>
      </a>
      <span>.</span>
    </p>
    <h3>
      <span>Opt-out tools to control cookies</span>
    </h3>
    <p>
      <span>
        Some of the third parties that collect information from or about you on
        PwC sites or apps may participate in the Digital Advertising
        Alliance&rsquo;s (&ldquo;DAA&rdquo;) self-regulatory program. To make
        choices about the use of information from or about you for online
        behavioral or interest-based advertising on websites (desktop and mobile
        browsers), please visit the{' '}
      </span>
      <a href="https://optout.aboutads.info/">
        <span>DAA</span>
      </a>
      <span>
        , and within mobile sites, you can download the DAA&rsquo;s AppChoices
        site from your mobile device at{' '}
      </span>
      <a href="http://www.aboutads.info/appchoices">
        <span>www.aboutads.info/appchoices</span>
      </a>
      <span> or from your device&rsquo;s app store.</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        You can set your browser to block or alert you about these cookies, but
        some parts of the site may no longer work.
      </span>
    </p>
    <h3>
      <span>Use of cookies</span>
    </h3>
    <p>
      <span>
        Below is a detailed list of the cookies we use on this site. The cookies
        listed apply to individuals residing in the US and elsewhere who access
        the Site. Depending on which jurisdiction you reside in (e.g. EU, U.K.),
        we may have disabled all but &quot;strictly necessary&quot; cookies.Such
        disablement is subject to change.
      </span>{' '}
      <span>
        We classify the use of cookies into the following categories: (1)
        necessary and (2) performance and functionality. You can find more
        information about each category in the relevant sections below. For each
        cookie listed below, we also note the duration of the cookie and
        identify whether it is a first party or third party cookie. Please note:
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>&lsquo;First party&rsquo; </strong>
      <span>
        cookies are directly stored by the website (or domain) you visit. These
        cookies allow website owners to collect analytics data, remember
        language settings, and perform other useful functions that provide a
        good user experience.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>&lsquo;Third party&rsquo;</strong>
      <span>
        {' '}
        cookies are created by domains that are not the website (or domain) that
        you are visiting. These are usually used for analytics and/or
        online-advertising purposes and placed on a website through adding
        scripts or tags. A third-party cookie is accessible on any website that
        loads the third party server&rsquo;s code.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Necessary cookies</strong>
    </p>
    <p>
      <span>
        These cookies are necessary for the site to function and cannot be
        switched off in our systems. They are usually only set in response to
        actions made by you which amount to a request for services, such as
        setting your privacy preferences, logging in or filling in forms.&nbsp;
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        You can set your browser to block or alert you about these cookies, but
        some parts of the site may no longer work.
      </span>
    </p>
    <p>&nbsp;</p>
    <table>
      <tbody>
        <tr>
          <td>
            <p>
              <strong>Cookie Name</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>Host&nbsp;</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>Cookie Type</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>Cookie Duration</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>accessToken</span>
            </p>
          </td>
          <td>
            <p>
              <span>pwc.com</span>
            </p>
          </td>
          <td>
            <p>
              <span>First party</span>
            </p>
          </td>
          <td>
            <p>
              <span>At the end of session</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>sessionID</span>
            </p>
          </td>
          <td>
            <p>
              <span>pwc.com</span>
            </p>
          </td>
          <td>
            <p>
              <span>First party</span>
            </p>
          </td>
          <td>
            <p>
              <span>At the end of session</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <p>
      <strong>Performance and functionality cookies</strong>
    </p>
    <p>
      <span>
        These cookies are used to enhance the performance and functionality of
        the site but are non-essential to its use. For example, these cookies
        may allow us to collect site performance and error data, enable customer
        support for users, and enable localized dates/times). They may be set by
        us or by our third party providers, including Pendo, whose services we
        have added to the site.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span>
        If you do not allow these cookies, we will not be able to monitor the
        performance of the site and certain of its functionality may become
        unavailable.
      </span>
    </p>
    <p>
      <br />
      <br />
    </p>
    <table>
      <tbody>
        <tr>
          <td>
            <p>
              <strong>Cookie Name</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>Host&nbsp;</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>Cookie Type</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>Cookie Duration</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>_pendo_accountId</span>
            </p>
          </td>
          <td>
            <p>
              <span>pendo.io</span>
            </p>
          </td>
          <td>
            <p>
              <span>Third party</span>
            </p>
          </td>
          <td>
            <p>
              <span>Does not expire</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>_pendo_visitorId</span>
            </p>
          </td>
          <td>
            <p>
              <span>pendo.io</span>
            </p>
          </td>
          <td>
            <p>
              <span>Third party</span>
            </p>
          </td>
          <td>
            <p>
              <span>Does not expire</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span>_pendo_meta</span>
            </p>
          </td>
          <td>
            <p>
              <span>pendo.io</span>
            </p>
          </td>
          <td>
            <p>
              <span>Third party</span>
            </p>
          </td>
          <td>
            <p>
              <span>Does not expire</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
      <br />
    </p>
  </>
);

const Cookies = ({ history }: any) => (
  <div className="privacy-p">
    <CookiesContent />
  </div>
);

export default Cookies;
