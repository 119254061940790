import { FieldItemWithLabel as FieldItem, UserInfo } from '@models';
import { cloneDeep } from 'lodash';
import { showMessage } from './message';

const clearLocalStorage = (): void => {
  Object.keys(window.localStorage).forEach((key: string) => {
    if (key !== 'welcomeCardClosed' && key !== 'loginType' && key !== 'from') {
      localStorage.removeItem(key);
    }
  });
  Object.keys(window.sessionStorage).forEach((key: string) => {
    if (key.startsWith('gateway-') || key === 'from') {
      return;
    }
    sessionStorage.removeItem(key);
  });
};

const isValidLogin = () => {
  const accessToken = window.localStorage.getItem('accessToken');

  const status = Boolean(accessToken);

  if (!status) {
    clearLocalStorage();
  }

  return status;
};

const isValidUserInfo = (userInfo: UserInfo) =>
  !userInfo ||
  !userInfo.uid ||
  !Array.isArray(userInfo?.permissions) ||
  // @ts-ignore
  (userInfo && userInfo.exp < Number(new Date()));

const isValidEnv = () =>
  Boolean(
    process &&
      process.env &&
      process.env.REACT_APP_authorization_endpoint &&
      process.env.REACT_APP_state &&
      process.env.REACT_APP_app_url &&
      process.env.REACT_APP_client_app_id,
  );

const exportAsExcel = (blob: Blob, fileName: string) => {
  const url = window.URL || window.webkitURL;
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(link.href);
};

const strNumSize = (tempNum: number) => {
  const stringNum = tempNum.toString();
  const index = stringNum.indexOf('.');
  let newNum = stringNum;
  if (index !== -1) {
    newNum = stringNum.substring(0, index);
  }
  return newNum.length;
};

const unitConvert = (cost: number) => {
  const moneyUnits = ['', 'K', 'M', 'B'];
  const dividend = 1000;
  let curentNum = cost;

  let curentUnit = moneyUnits[0];

  for (let i = 0; i < 4; i++) {
    curentUnit = moneyUnits[i];
    if (strNumSize(curentNum) < 4) {
      break;
    } else if (i !== 3) {
      curentNum = curentNum / dividend;
    }
  }
  return curentNum && !isNaN(Number(curentNum))
    ? `$${Number(curentNum).toFixed(curentUnit === '' ? 0 : 1)} ${curentUnit}`
    : 0;
};

const unitConvertWithoutUnit = (cost: number) => {
  if (!cost) {
    if (cost === 0) {
      return `$0`;
    }
    return '';
  }
  return `$${cost}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const calculateMedian = (collect: number[]) => {
  const args = [...(collect || [])];
  args.sort((a: number, b: number) => a - b);
  if (args.length % 2 === 0) {
    return (args[args.length / 2] + args[args.length / 2 - 1]) / 2;
  }
  return args[parseInt(String(args.length / 2))];
};

const disableFormFields = (fields: FieldItem[]) => {
  let newFields = cloneDeep(fields);
  newFields = newFields.map((item: FieldItem, index: number) => {
    if (item.subFields && item.subFields.length) {
      item.subFields = item.subFields.map(
        (subItem: FieldItem, subIndex: number) => {
          subItem.disabled = true;
          return subItem;
        },
      );
    }
    item.disabled = true;
    return item;
  });
  return newFields;
};

const cycleSave = async (apiRequest: any, parmas: any, time: number = 1) => {
  try {
    const res = await apiRequest(parmas);
    if (!res) {
      showMessage({
        status: 'error',
        message:
          'Save failed. The network is having trouble connecting to the server. Please try again later',
      });
    }
  } catch (err) {
    console.error(err);
    if (time < 3) {
      if (time === 1) {
        showMessage({
          status: 'warning',
          message: 'Saving',
        });
      }
      cycleSave(apiRequest, parmas, time + 1);
    } else {
      showMessage({
        status: 'error',
        message:
          'Save failed. The network is having trouble connecting to the server. Please try again later',
      });
    }
  }
};

const functionNameGenerator = (
  functionList: any,
  key: string,
  value: string,
  hasBrace: boolean = false,
): string => {
  const existNames: string[] = [];
  const reg = hasBrace
    ? new RegExp(/^[(]\d+[)]$/, 'i')
    : new RegExp(/^\d+$/, 'i');
  functionList.forEach((func: any) => {
    if (value && func[key] && func[key].toLowerCase() === value.toLowerCase()) {
      existNames.push(func[key]);
    } else {
      const arr = func[key].toLowerCase().split(value.toLowerCase());
      if (!arr[0] && reg.test(arr[1])) {
        existNames.push(func[key]);
      }
    }
  });
  if (existNames.length) {
    existNames.sort((a, b) => a.localeCompare(b));
  } else {
    return value;
  }
  const lastName = existNames[existNames.length - 1];
  if (lastName.toLowerCase() === value.toLowerCase()) {
    return hasBrace ? `${value}(1)` : `${value}1`;
  }
  const lastNameArr = lastName.toLowerCase().split(value.toLowerCase());
  const numberString = hasBrace
    ? lastNameArr[1].split('(')[1].split(')')[0]
    : lastNameArr[1];
  if (numberString) {
    return hasBrace
      ? `${value}(${Number(numberString) + 1})`
      : `${value}${Number(numberString) + 1}`;
  }
  return value;
};

const getFirstPathname = (url: string) => {
  if(url.startsWith('/')) return url.split('/')[1];
  if(url.startsWith('http')) {
    try {
      return  new URL(url).pathname?.split('/')[1]
    } catch (e) {
      console.log(e);
    }
  }
  return '';
}

const getValueFromSearchParam = (...keys: string[]) => {
  const searchParam = new URLSearchParams(window.location.search);
  return keys.reduce((result: { [key: string]: string | null }, current) => {
    result[current] = searchParam.get(current);
    return result;
  }, {});
};

export {
  isValidLogin,
  isValidUserInfo,
  isValidEnv,
  exportAsExcel,
  unitConvert,
  unitConvertWithoutUnit,
  calculateMedian,
  clearLocalStorage,
  disableFormFields,
  cycleSave,
  functionNameGenerator,
  getFirstPathname,
  getValueFromSearchParam,
};
