import React from 'react';
import '@styles/components/logo.scss';

function Logo() {
  return (
    <div className="logo-wrapper">
      <div className="logo" />
    </div>
  );
}

export default Logo;
