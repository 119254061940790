import React, { useState, memo, useEffect, useMemo, useRef } from 'react';

import { Select } from 'antd';
import '@styles/components/selectorMul.scss';
const { Option } = Select;
const SelectMul = (props: any) => {
  const mulSelectRef: any = useRef<any>(null);
  const { options = [], form, defaultOptions = [], name, value = [] } = props;
  const _options: any = useMemo(() => [], []);
  const [children, setChildren] = useState<Array<JSX.Element>>();

  const handleChange = (val: any) => {
    let hasError = false;
    val.forEach((item: string, i: number) => {
      if (
        !item ||
        !item.trim() ||
        (item && item.trim() && /[^\w\s[(][)]]/g.test(item.trim()))
      ) {
        hasError = true;
        form.setFields([{ name, errors: ['has error'] }]);
      }
    });
    if (!hasError) {
      form.setFields([
        {
          name,
          errors: null,
          value: val,
        },
      ]);
    }
    setTimeout(() => {
      mulSelectRef.current.blur();
    }, 0);
  };

  useEffect(() => {
    options.forEach((item: any, index: number) => {
      _options.push(
        <Option key={index} value={item.value}>
          {item.label}
        </Option>,
      );
      setChildren(_options);
    });
  }, [options, _options]);

  const Icon = () => (
    <span
      className="appkiticon icon-search-fill a-font-30 "
      style={{ color: '#999' }}
    />
  );

  return (
    <Select
      ref={mulSelectRef}
      className="multiple-select"
      mode="tags"
      style={{ width: '100%' }}
      onChange={handleChange}
      showArrow
      suffixIcon={<Icon />}
      defaultValue={defaultOptions}
      value={value || []}
      disabled={props.disabled || false}
      getPopupContainer={(): any => document.querySelector('.ant-drawer-body')}
    >
      {children}
    </Select>
  );
};

export default memo(SelectMul);
