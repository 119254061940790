import React from 'react';
import { NavLink } from 'react-router-dom';
import '@styles/layout.scss';

function AppFooter() {
  const fullYear = new Date().getFullYear();
  return (
    <div className="app-footer">
      <div className="app-footer-content">
        <ul>
          <li>Privacy Statement</li>
          <li>Cookie Statement</li>
          <li>Terms of Use</li>
          <li>Project Disclaimer</li>
        </ul>
        <div className="app-footer-desc">
          © {fullYear} PwC. All rights reserved. PwC refers to the PwC network
          and/or more of its member firms, each of which is a separate legal
          entity.Please see{' '}
          <a href="https://www.pwc.com/structure">www.pwc.com/structure</a> for
          further details.
        </div>
        <div className="app-footer-desc">
          Confidential Information for the sole benefit and use of PwC’s client,
          which should be read in conjunction with the following{' '}
          <NavLink to="/privacy-statement">statement</NavLink>.
        </div>
      </div>
    </div>
  );
}

export default AppFooter;
