import {useCallback, useEffect, useState} from "react";
import {getPrmssns, requestAccess} from "@services";
import {useSelector} from "react-redux";
import {RootStoreType} from "@reducer";
const { PopAlert } = require('appkit-react');

function useRequestAccessService() {
    const userInfo = useSelector((store:RootStoreType) => store.login.userInfo);
    const [loading, setLoading] = useState(false);
    const { permissions } = userInfo;
    const [allowedApps, setAllowedApps] = useState(permissions);
    const [pendingApps, setPendingApps] = useState<string[]>([]);
    const fetchPermissions = useCallback(async () => {
        setLoading(true);
        const res = await getPrmssns();
        const pendingRequestApp = res?.pendingRequestApp || [];
        const refetchedPermissions = res?.permissions || [];

        setAllowedApps(refetchedPermissions);
        setPendingApps(pendingRequestApp);
        setLoading(false);
    }, []);
    const sendRequest = useCallback(async (userInfo, requestApp, reason) => {
        setLoading(true);
        await requestAccess(userInfo, requestApp || '', reason);
        setLoading(false);
        PopAlert({
            oneLineDisplay: true,
            duration: 2000,
            status: 'success',
            message: 'Request has been sent successfully',
            className: 'popup-success',
        });
    }, []);

    useEffect(() => {
        if(userInfo.isClient) {
            setAllowedApps(userInfo.permissions)
        }
    }, [userInfo]);

    return {
        allowedApps,
        pendingApps,
        fetchPermissions,
        loading,
        sendRequest,
    };
}

export default useRequestAccessService;