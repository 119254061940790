import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import {
  SectorInfo,
  UserInfo,
  ProjectInfo,
  ProjectFunction,
  TemplateList,
  ProjectEntanglement,
  UserInfoByGuid,
  CustomizedFieldModel,
  Operationalization,
} from '@models';

// actions
export enum CommonActionsEnum {
  CHANGE_BREADCRUMB = 'CHANGE_BREADCRUMB',
  CHANGE_CURRENT_PROJECT_ID = 'CHANGE_CURRENT_PROJECT_ID',
}

export enum LoginActionsEnum {
  SAVE_USER_INFO = 'SAVE_USER_INFO',
}

export enum ProjectActionsEnum {
  GET_SECTOR_INFO = 'GET_SECTOR_INFO',
  GET_SECTOR_INFO_PENDING = 'GET_SECTOR_INFO_PENDING',
  GET_SECTOR_INFO_FULFILLED = 'GET_SECTOR_INFO_FULFILLED',
  GET_SECTOR_INFO_WITHOUT_X = 'GET_SECTOR_INFO_WITHOUT_X',
  GET_SECTOR_INFO_WITHOUT_X_PENDING = 'GET_SECTOR_INFO_WITHOUT_X_PENDING',
  GET_SECTOR_INFO_WITHOUT_X_FULFILLED = 'GET_SECTOR_INFO_WITHOUT_X_FULFILLED',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_PENDING = 'CREATE_PROJECT_PENDING',
  CREATE_PROJECT_FULFILLED = 'CREATE_PROJECT_FULFILLED',
  CLEAR_LOADING_REDIRECT_STATES = 'CLEAR_LOADING_REDIRECT_STATES',
  GET_PROJECT_INFO = 'GET_PROJECT_INFO',
  GET_PROJECT_INFO_PENDING = 'GET_PROJECT_INFO_PENDING',
  GET_PROJECT_INFO_FULFILLED = 'GET_PROJECT_INFO_FULFILLED',
  GET_USER_INFO = 'GET_USER_INFO',
  GET_USER_INFO_PENDING = 'GET_USER_INFO_PENDING',
  GET_USER_INFO_FULFILLED = 'GET_USER_INFO_FULFILLED',
  GET_CUSTOMIZED_MODEL = 'GET_CUSTOMIZED_MODEL',
  GET_CUSTOMIZED_MODEL_PENDING = 'GET_CUSTOMIZED_MODEL_PENDING',
  GET_CUSTOMIZED_MODEL_FULFILLED = 'GET_CUSTOMIZED_MODEL_FULFILLED',
}

export enum ProjectFunctionActionsEnum {
  GET_PROJECT_FUNCTIONS = 'GET_PROJECT_FUNCTIONS',
  GET_PROJECT_FUNCTIONS_PENDING = 'GET_PROJECT_FUNCTIONS_PENDING',
  GET_PROJECT_FUNCTIONS_FULFILLED = 'GET_PROJECT_FUNCTIONS_FULFILLED',
  CHANGE_PROJECT_FUNCTIONS = 'CHANGE_PROJECT_FUNCTIONS',
  RESET_PROJECT_FUNCTIONS = 'RESET_PROJECT_FUNCTIONS',
  SAVE_PROJECT_FUNCTION = 'SAVE_PROJECT_FUNCTION',
  SAVE_PROJECT_FUNCTION_PENDING = 'SAVE_PROJECT_FUNCTION_PENDING',
  SAVE_PROJECT_FUNCTION_FULFILLED = 'SAVE_PROJECT_FUNCTION_FULFILLED',
  GET_ALL_TEMPLATE_LIST = 'GET_ALL_TEMPLATE_LIST',
  GET_ALL_TEMPLATE_LIST_PENDING = 'GET_ALL_TEMPLATE_LIST_PENDING',
  GET_ALL_TEMPLATE_LIST_FULFILLED = 'GET_ALL_TEMPLATE_LIST_FULFILLED',
}

export enum EntanglementsActionsEnum {
  GET_ENTANGLEMENTS = 'GET_ENTANGLEMENTS',
  GET_ENTANGLEMENTS_PENDING = 'GET_ENTANGLEMENTS_PENDING',
  GET_ENTANGLEMENTS_FULFILLED = 'GET_ENTANGLEMENTS_FULFILLED',
  CHANGE_ENTANGLEMENTS = 'CHANGE_ENTANGLEMENTS',
  CHANGE_ENTANGLEMENTS_OPERATE_LOADING = 'CHANGE_ENTANGLEMENTS_OPERATE_LOADING',
}

export enum TransitionsActionsEnum {
  GET_TRANSITIONS = 'GET_TRANSITIONS',
  GET_TRANSITIONS_PENDING = 'GET_TRANSITIONS_PENDING',
  CHANGE_TRANSITIONS = 'CHANGE_TRANSITIONS',
  GET_TRANSITIONS_FULFILLED = 'GET_TRANSITIONS_FULFILLED',
  CHANGE_TRANSITIONS_OPERATE_LOADING = 'CHANGE_TRANSITIONS_OPERATE_LOADING',
}

export enum TsaActionsEnum {
  GET_TSA = 'GET_TSA',
  GET_TSA_PENDING = 'GET_TSA_PENDING',
  GET_TSA_FULFILLED = 'GET_TSA_FULFILLED',
  CHANGE_TSA = 'CHANGE_TSA',
  CHANGE_TSA_OPERATE_LOADING = 'CHANGE_TSA_OPERATE_LOADING',
  GET_TSA_DEPENDENCY = 'GET_TSA_DEPENDENCY',
  GET_TSA_DEPENDENCY_PENDING = 'GET_TSA_DEPENDENCY_PENDING',
  GET_TSA_DEPENDENCY_FULFILLED = 'GET_TSA_DEPENDENCY_FULFILLED',
}

export enum operationalizationActionsEnum {
  GET_OPERATIONALIZATIONS = 'GET_OPERATIONALIZATIONS',
  GET_OPERATIONALIZATIONS_PENDING = 'GET_OPERATIONALIZATIONS_PENDING',
  GET_OPERATIONALIZATIONS_FULFILLED = 'GET_OPERATIONALIZATIONS_FULFILLED',
  GET_COLUMNOPERATIONALIZATIONS = 'GET_COLUMNOPERATIONALIZATIONS',
  GET_COLUMNOPERATIONALIZATIONS_PENDING = 'GET_COLUMNOPERATIONALIZATIONS_PENDING',
  GET_COLUMNOPERATIONALIZATIONS_FULFILLED = 'GET_COLUMNOPERATIONALIZATIONS_FULFILLED',
  CHANGE_OPERATIONALIZATIONS = 'CHANGE_OPERATIONALIZATIONS',
}

// reducers
export interface CommonReducerState {
  currentProjectId: string;
  isLoading: boolean;
  breadcrumb: Route[];
}

export interface LoginReducerState {
  userInfo: UserInfo;
  isLogin: boolean;
}

export interface ProjectReducerState {
  sectorsInfo: SectorInfo[];
  sectorsInfoWithoutX: SectorInfo[];
  isSectorsLoading: boolean;
  isUserLoading: boolean;
  isSectorsWithoutXLoading: boolean;
  isProjectCreateLoading: boolean;
  redirectToList: boolean;
  projectsInfo: ProjectInfo[];
  isProjectsLoading: boolean;
  userInfoByGuid: UserInfoByGuid;
  isCustomizedFieldModelLoading: boolean;
  customizedFieldModel: CustomizedFieldModel[];
}

export interface ProjectFunctionReducerState {
  projectFunctions: ProjectFunction[];
  resetProjectFunctions: ProjectFunction[];
  templateFunctions: TemplateList[];
  isFunctionsLoading: boolean;
  isFunctionsSaveLoading: boolean;
  isTemplateGetLoading: boolean;
  isSaveFunctionsLoading: boolean;
  isSaveFunctionsCompleted: boolean;
}

export interface EntanglementReducerState {
  projectEntanglements: ProjectEntanglement[];
  isEntanglementsLoading: boolean;
  isEntanglementsOperateLoading: boolean;
}

export interface TransitionReducerState {
  projectEntanglements: ProjectEntanglement[];
  isEntanglementsLoading: boolean;
  isEntanglementsOperateLoading: boolean;
}

export interface TsaReducerState {
  projectEntanglements: ProjectEntanglement[];
  isEntanglementsLoading: boolean;
  isEntanglementsOperateLoading: boolean;
  tsaDependencies: any[];
  isDependenciesLoading: boolean;
}
export interface OperationalizationReducerState {
  operationalizations: Operationalization[];
  isOperationalizationLoading: boolean;
  operationalizationsColumn: any[];
  isOperationalizationColumnLoading: boolean;
}
