import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import { loginAction } from '@action';
import '@styles/components/termsModal.scss';
import { connect } from 'react-redux';

import { TermsContent } from './Terms';
import { CookiesContent } from './Cookies';
import { PolicyContent } from './Policy';

import { setAcceptTermsRequest } from '@services';

const Contents = () => {
  const [chosenContent, chooseContent] = useState('policy');
  const ref = useRef<any>(null);

  let Content: any;

  switch (chosenContent) {
    case 'policy':
      Content = PolicyContent;
      break;
    case 'cookies':
      Content = CookiesContent;
      break;
    case 'terms':
      Content = TermsContent;
      break;
    default:
      Content = PolicyContent;
  }

  const onSpanClick = (cType: any) => () => {
    chooseContent(cType);
    ref.current && ref.current.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <h1>Terms & Conditions</h1>
      <p>
        By clicking Accept, you agree to our{' '}
        <span onClick={onSpanClick('terms')}>Terms of Use</span> and that you
        have read our{' '}
        <span onClick={onSpanClick('policy')}>Privacy Statement</span> and{' '}
        <span onClick={onSpanClick('cookies')}>Cookie Statement</span>.
      </p>
      <div className="terms-content" ref={ref}>
        <Content />
      </div>
    </div>
  );
};

const TermsModal = ({ logout, visible, onAccept, userInfo }: any) => {
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);

  const handleDecline = () => {
    logout();
  };

  const handleAccept = async () => {
    setIsAcceptLoading(true);
    setAcceptTermsRequest(userInfo.uid).then((res: any) => {
      if (res) {
        window.localStorage && window.localStorage.setItem('accepted', 'true');
        setIsAcceptLoading(false);
        onAccept();
      }
    });
  };

  return (
    <Modal
      wrapClassName="terms-acceptance-modal"
      centered
      confirmLoading={isAcceptLoading}
      closable={false}
      maskClosable={false}
      okText="Accept"
      cancelText="Decline"
      // zIndex="135"
      visible={visible}
      destroyOnClose
      getContainer={(): any => document.querySelector('.app-content')}
      maskStyle={{
        background: 'rgba(0,0,0,0.7)',
      }}
      // bodyStyle={{
      //   width: '42.5rem',
      //   height: '75vh',
      // }}
      onCancel={handleDecline}
      onOk={handleAccept}
    >
      <Contents />
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => {
    dispatch(loginAction.logout());
  },
});

export default connect(() => ({}), mapDispatchToProps)(TermsModal);
