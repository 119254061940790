import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthRoute } from '@components';

interface RoutesProps {
  routeData: Array<any>;
  rootPath: string;
}

function Routes(props: RoutesProps) {
  const { routeData, rootPath } = props;
  const routes = useMemo(
    () =>
      routeData.map((route) => {
        const {
          path: _path,
          exact,
          render,
          main: Comp,
          visitor,
          ...rest
        } = route;
        const path = `${rootPath}${_path}`;
        let RouteComp: any = AuthRoute;

        if (visitor) {
          RouteComp = Route;
        }
        return (
          <RouteComp
            key={path}
            path={path}
            exact={exact || false}
            render={(renderProps: any) =>
              render ? (
                render({
                  ...renderProps,
                  ...rest,
                })
              ) : (
                <Comp {...props} {...rest} />
              )
            }
          />
        );
      }),
    [routeData, rootPath, props],
  );

  return <Switch>{routes}</Switch>;
}

export default Routes;
