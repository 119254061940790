import React from 'react';

export const PolicyContent = ({ history }: any) => (
  <>
    <h2>
      <strong>Privacy Policy</strong>
    </h2>
    <h3>
      <strong>
        <em>Introduction</em>
      </strong>
    </h3>
    <p>
      <span>
        M&amp;A Gateway is an offering of PricewaterhouseCoopers Advisory
        Product Sales LLC (&ldquo;PwC&rdquo;), a wholly-owned subsidiary of
        PricewaterhouseCoopers LLP, the US firm of the PricewaterhouseCoopers
        global network of firms. For further details on the structure of the
        global network of firms, please{' '}
      </span>
      <a href="http://www.pwc.com/structure">
        <span>www.pwc.com/structure</span>
      </a>
      <span>.&nbsp;</span>
    </p>
    <p>
      <span>
        PwC is committed to protecting your privacy. This privacy statement
        explains the types of information we collect when you use or access
        M&amp;A Gateway (also referred to as the &ldquo;Site&rdquo;), and how we
        use, share, and protect this information. Note, this privacy statement
        applies solely to M&amp;A Gateway and does not apply to other PwC
        websites or applications.&nbsp;
      </span>
    </p>
    <h3>
      <strong>
        <em>Information Collection&nbsp;</em>
      </strong>
    </h3>
    <p>
      <span>
        When you access or otherwise use the Site, we will collect information
        about you and how you interact with the Site. We collect information
        about you through: (i) information you provide; (ii) automated
        technologies; and (iii) third parties.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Information You Provide</em>
      </strong>
    </p>
    <p>
      <span>
        When you access the Site, you may choose to provide information about
        yourself such as: identifiers and contact information including your
        name, and email address. By way of example, you may choose to provide
        your information in the following circumstances:
      </span>
    </p>
    <ul>
      <li>
        <span>Registration for or otherwise accessing the Site.</span>
      </li>
      <li>
        <span>Requesting support&nbsp;</span>
      </li>
    </ul>
    <p>
      <span>
        PwC does not intend to collect sensitive personal information about you
        through the Site. Sensitive personal information includes a number of
        types of data relating to: race or ethnic origin; political opinions;
        religious or other similar beliefs; trade union membership; physical or
        mental health; sexual life or criminal record. Please do not submit
        sensitive personal information through the Site.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Automatically Collected Information</em>
      </strong>
      <span>&nbsp;</span>
    </p>
    <p>
      <span>
        We may use online identification technologies, such as cookies, on some
        pages of our Site (&ldquo;Cookies&rdquo;). The information we collect
        using these technologies includes identifiers and internet activity
        information such as: IP address, browser type and language, access time,
        and referring website. For more information about the Cookies used on
        the Site, please review our{' '}
      </span>
      <span>
        <span
          onClick={() => history.replace('/privacy?tab=cookie')}
          className="internal-link"
        >
          Cookie Disclosure
        </span>
        .&nbsp;
      </span>
    </p>
    <p>
      <span>
        PwC also uses third-party website analytics providers, which may set
        Cookies in your browser or device. Site activity information collected
        by these analytics providers may be combined with personal information
        held by PwC. For more information about these third-party tools, please
        review our{' '}
      </span>
      <span>
        <span
          onClick={() => history.replace('/privacy?tab=cookie')}
          className="internal-link"
        >
          Cookie Disclosure
        </span>
        .&nbsp;
      </span>{' '}
    </p>
    <p>
      <span>
        We also use usage information to generate various reports regarding use
        of the Site. These reports contain aggregated information about users
        and do not single out users individually.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Information from Third Parties</em>
      </strong>
    </p>
    <p>
      <span>
        We may also collect the following information about you in connection
        with the Site from{' '}
      </span>
      <span>other PwC Member Firms.</span>
    </p>
    <p>
      <span>
        For PwC Member Firm locations, see
        https://www.pwc.com/gx/en/about/office-locations.html
      </span>
    </p>
    <p>
      <span>
        We may share personal data with other PwC member firms where necessary
        in connection with the purposes described in this privacy statement. For
        example, when providing professional services to a client we may share
        personal information with PwC Member Firms in different territories that
        are involved in providing services to that client.
      </span>
    </p>
    <h3>
      <strong>
        <em>Information Use</em>
      </strong>
    </h3>
    <p>
      <span>
        We use the information we collect as described above for various
        purposes depending on the context in which we collected such
        information. For example, it may be necessary for us to process your
        information in order to process a transaction or provide a service you
        have requested or otherwise in accordance with a contract between us, or
        we may process your information in accordance with your prior consent
        (where you have provided such consent).&nbsp;
      </span>
    </p>

    <p>
      <span>
        We use the information we collect for the following business
        purposes:&nbsp;
      </span>
    </p>

    <ul>
      <li>
        <span>
          To operate, maintain, customize, analyze and improve the Site;&nbsp;
        </span>
      </li>
      <li>
        <span>
          To maintain and improve the safety and security of the Site, and to
          run our internal operations;&nbsp;
        </span>
      </li>
      <li>
        <span>
          To prevent and enhance protection against fraud, spam, harassment,
          intellectual property infringement, crime, and security risks;&nbsp;
        </span>
      </li>
      <li>
        <span>
          To comply with law, rules, regulations, legal obligations,
          professional and industry standards, as well as respond to and
          complying with subpoena, search warrant or other legal process,
          including establishing, exercising, or defending our legal
          rights;&nbsp;
        </span>
      </li>
      <li>
        <span>
          To fulfill your orders/requests and respond to your inquiries;&nbsp;
        </span>
      </li>
      <li>
        <span>
          To send you service-related notifications or communications;&nbsp;
        </span>
      </li>
      <li>
        <span>To conduct customer satisfaction surveys;&nbsp;</span>
      </li>
      <li>
        <span>
          To improve our products and services and explore ways to develop and
          grow our business;&nbsp;
        </span>
      </li>
      <li>
        <span>
          To send communications about PwC products, services, and events, and
          to facilitate events with PwC and third parties.&nbsp;
        </span>
      </li>
    </ul>

    <h3>
      <strong>
        <em>Information Sharing</em>
      </strong>
    </h3>
    <p>
      <span>
        We are part of a global network of firms. In common with other
        professional service providers, PwC may transfer or disclose the
        information it collects, including your information, to third party
        contractors, subcontractors, subsidiaries, and/or other PwC firms for
        the purposes for which you have submitted the information and for the
        administration of our system or Site and/or other internal,
        administrative purposes. We transfer your information to third
        party&nbsp;
      </span>
    </p>
    <p>
      <span>
        service providers of identity management, website hosting and
        management, data analysis, data backup, security, and storage
        services.&nbsp;
      </span>
    </p>
    <p>
      <span>
        PwC may also disclose information to third parties under the following
        circumstances:&nbsp;
      </span>
    </p>
    <ul>
      <li>
        <span>When explicitly requested by a user;&nbsp;</span>
      </li>
      <li>
        <span>
          As necessary to comply with law, rules, regulations, legal
          obligations, professional and industry standards, as well as respond
          to and complying with subpoena, search warrant or other legal process,
          including establishing, exercising, or defending our legal
          rights;&nbsp;
        </span>
      </li>
      <li>
        <span>
          As necessary in connection with a corporate reorganization, merger,
          sale, joint venture or other disposition of all or any portion of our
          business, assets or capital.&nbsp;
        </span>
      </li>
    </ul>
    <p>
      <span>
        We do not sell personal information collected in connection with the
        Site nor do we disseminate personal information collected in connection
        with the Site to outside parties for consumer marketing purposes or host
        mailings on behalf of third parties.&nbsp;
      </span>
    </p>
    <h3>
      <strong>
        <em>Information Transfer</em>
      </strong>
    </h3>
    <p>
      <span>
        Your information may be transferred outside of the U.S. or your country
        of residence. This includes to countries outside the European Economic
        Area (&ldquo;EEA&rdquo;) and to countries that do not have laws that
        provide the same degree of protection for personal information. In
        accordance with applicable legal requirements, we take appropriate
        measures to facilitate adequate protection for any information so
        transferred.&nbsp;
      </span>
    </p>
    <p>
      <span>
        Where we transfer personal information outside of the EEA to a country
        or framework not determined by the European Commission as providing an
        adequate level of protection for personal information, the transfers
        will be under an agreement which covers European Union requirements for
        such transfers, such as standard contractual clauses. The European
        Commission approved standard contractual clauses are available
        here.&nbsp;
      </span>
    </p>
    <p>
      <span>
        PricewaterhouseCoopers LLP and its affiliated U.S. subsidiaries also
        adhere to the EU &ndash; U.S. Privacy Shield Framework and the Swiss
        &ndash; U.S. Privacy Shield Framework as set forth by the U.S.
        Department of Commerce. To learn more, see our Privacy Shield
        Policy.&nbsp;
      </span>
    </p>

    <h3>
      <strong>
        <em>Information retention&nbsp;</em>
      </strong>
    </h3>
    <p>
      <span>
        We will retain the personal information collected by us through the Site
        for as long as is necessary for the purpose(s) for which it was
        collected as set out in this privacy statement, provided that personal
        information may be held for longer periods where extended retention
        periods are required by law, regulation or professional standards, and
        in order to establish, exercise or defend our legal rights&nbsp;
      </span>
    </p>
    <h3>
      <strong>
        <em>Your choices&nbsp;</em>
      </strong>
    </h3>
    <p>
      <strong>
        <em>Unsubscribe&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        Should you wish to unsubscribe from our mailing list or any
        registrations, we will provide instructions in communications to you.
      </span>
    </p>
    <p>
      <strong>
        <em>Managing cookies&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        If you are concerned about cookies, most browsers permit individuals to
        decline cookies. In most cases, you may refuse or delete one or more
        cookies and still access the Site, but Site functionality may be
        impaired. After you finish browsing this Site, you may delete Site
        cookies from your system if you wish. For more information about how to
        manage your cookie preferences, please review our{' '}
        <span
          onClick={() => history.replace('/privacy?tab=cookie')}
          className="internal-link"
        >
          Cookie Disclosure
        </span>
        .
      </span>
    </p>
    <p>
      <strong>
        <em>Do Not Track&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        Do Not Track (&ldquo;DNT&rdquo;) is a privacy preference that you can
        set in your web browser to send a message to the website operator
        requesting not to be tracked. Currently, we do not respond to these DNT
        signals. For more information about DNT, visit{' '}
      </span>
      <a href="https://allaboutdnt.com/">
        <span>https://allaboutdnt.com/</span>
      </a>
      <span>.&nbsp;</span>
    </p>
    <p>
      <strong>
        <em>Access&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        You may review and change your personal information by logging into the
        Site and visiting your account profile page. If you would like to update
        your information or request to have outdated information removed, please
        contact us at:{' '}
      </span>
      <a href="mailto:us_ddv_magateway@pwc.com">
        <span>us_ddv_magateway@pwc.com</span>
      </a>
      <span>.</span>
    </p>
    <p>
      <strong>
        <em>Rights&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        Depending on the jurisdiction in which you are located, you may have
        certain rights with respect to your personal information. Subject to
        applicable law, these rights may include the right to:&nbsp;
      </span>
    </p>
    <ul>
      <li>
        <span>
          Know what personal information about you we have collected, disclosed,
          and/or sold, as applicable;&nbsp;
        </span>
      </li>
      <li>
        <span>Obtain a copy of your personal information;&nbsp;</span>
      </li>
      <li>
        <span>Delete your personal information;&nbsp;</span>
      </li>
      <li>
        <span>
          Direct us not to sell your personal information (but as noted above,
          we do not sell personal information collected about you in connection
          with the Site);&nbsp;
        </span>
      </li>
      <li>
        <span>Correct or update your personal information;&nbsp;</span>
      </li>
      <li>
        <span>Restrict the processing of your personal information;&nbsp;</span>
      </li>
      <li>
        <span>
          Object to the processing of your personal information;&nbsp;
        </span>
      </li>
      <li>
        <span>
          Withdraw your consent for processing (where processing is based on
          your consent). Please note that if you withdraw your consent, we may
          not be able to provide you the services for which it was necessary to
          process your information based on your consent; and&nbsp;
        </span>
      </li>
      <li>
        <span>
          Exercise your privacy rights without discriminatory treatment from
          us.&nbsp;
        </span>
      </li>
    </ul>
    <p>
      <span>
        To exercise any of the rights described above, please submit a
        verifiable request to us by either using our request form or calling us
        at 1-888-438-4427.&nbsp;
      </span>
    </p>
    <p>
      <span>
        The verifiable request must describe your request with sufficient detail
        that allows us to properly understand, evaluate, and respond to it.
        Further, you must provide us with sufficient information that allows us
        to reasonably verify you are the person about whom we collected personal
        information or an authorized representative. Please, however, refrain
        from sending us sensitive personal information. Where permitted by law,
        you may use an authorized agent to submit a request on your behalf if
        you have provided the authorized agent signed permission to do so.&nbsp;
      </span>
    </p>
    <p>
      <span>
        We may not be able to fulfill your request if we cannot verify your
        identity or authority to make the request and confirm the personal
        information relates to you. Making a verifiable request does not require
        you to create an account with us. We will only use personal information
        provided in a verifiable request to verify the requestor&rsquo;s
        identity or authority to make the request.&nbsp;
      </span>
    </p>
    <p>
      <span>
        We will respond to your request within a reasonable timeframe in
        accordance with applicable law. We reserve the right to charge an
        appropriate fee for complying with a request where permitted by
        applicable law, and to deny a request where it may be manifestly
        unfounded, excessive, or unwarranted under applicable law.&nbsp;
      </span>
    </p>
    <p>
      <span>
        We hope that you won&rsquo;t ever need to, but if you do want to
        complain about our use of personal information, please send us a message
        with the details of your complaint using our general contact form. You
        may also contact our EU Representative, PwC Malta, by completing our EU
        Representative contact form. Applicable laws may also give you the right
        to lodge a complaint with the data protection authority in your
        country.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Security&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        PwC has implemented generally accepted standards of technology and
        operational security designed to protect personal information from
        unauthorized access, loss, misuse, alteration, or destruction. Only
        authorized PwC personnel and the third parties described in this privacy
        statement are provided access to personal information and these
        employees and third parties have agreed to maintain the confidentiality
        of this information.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Third Party Sites&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        This [Site/Application] may link to other websites which do not operate
        under PwC&apos;s privacy practices. When you link to other websites,
        PwC&apos;s privacy practices no longer apply. We encourage you to review
        the privacy statement of each website you visit before disclosing any
        personal information.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Children&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        PwC understands the importance of protecting children&apos;s privacy,
        especially in an online environment. The Site&nbsp; is not intentionally
        designed for or directed at children under the age of 13 years. It is
        PwC&apos;s policy never to knowingly collect or maintain personal
        information about children under the age of 13.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Modifications&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        PwC may update this privacy statement at any time by publishing an
        updated version here. You can access the most current version of this
        privacy statement at any time on this Site.&nbsp;
      </span>
    </p>
    <p>
      <strong>
        <em>Contact Us&nbsp;</em>
      </strong>
    </p>
    <p>
      <span>
        If you have questions about this privacy statement or about our privacy
        practices, please contact us at{' '}
      </span>
      <a href="mailto:us_ddv_magateway@pwc.com">
        <span>us_ddv_magateway@pwc.com</span>
      </a>
      <span>.</span>
    </p>
    <br />
    <br />
  </>
);

const Policy = ({ history }: any) => (
  <div className="privacy-p">
    <PolicyContent history={history} />
  </div>
);

export default Policy;
