import React from 'react';

export const TermsContent = () => (
  <>
    <h2 className="terms-title">
      <strong>Terms of Use</strong>
    </h2>
    <p>
      Please read these Terms of Service (the &ldquo;<u>TOS</u>&rdquo;)
      carefully. &nbsp;This TOS governs any use of (including access to) any PwC
      products and services made available through magateway.pwc.com (each,
      together with any information, data, content or other materials and
      services available on or through such product or service, successor
      product(s) or service(s) to such product or service, and any website or
      mobile software application through which PwC makes such product or
      service or successor product(s) or service(s) available to you, a &ldquo;
      <u>Solution</u>&rdquo;). Your use of a Solution constitutes your consent
      to this TOS and any applicable Solution Terms, as defined below
      (collectively, the &ldquo;
      <u>Agreement</u>&rdquo;).
    </p>
    <p>
      With respect to a particular Solution, this Agreement is between you and
      PwC Product Sales LLC or PricewaterhouseCoopers Advisory Services LLC,
      whichever is the provider of the Solution (&ldquo;<u>PwC</u>&rdquo; or
      &ldquo;<u>we</u>&rdquo; or &ldquo;<u>us</u>&rdquo;).
    </p>
    <p>
      Additional terms may apply with respect to particular Solutions (as
      updated by PwC from time to time, the &ldquo;<u>Solution Terms</u>
      &rdquo;). Solution Terms may be set forth in Appendix A to this TOS, made
      available on or through the Solutions, or otherwise made available to you
      by PwC. In the event of any irreconcilable conflict between the TOS and
      any Solution Terms, the Solution Terms will control to the extent of such
      conflict, solely with respect to the applicable Solution.
    </p>
    <p>
      By using a Solution, you affirm that you are of legal age to enter into
      this Agreement or, if you are not, that you have obtained parental or
      guardian consent to enter into this Agreement.
    </p>
    <p>
      If you are an individual accessing or using a Solution on behalf of, or
      for the benefit of, any corporation, partnership or other entity with
      which you are associated (an &ldquo;<u>Organization</u>&rdquo;), and such
      Organization has entered into one or more separate &nbsp;agreements
      governing the use of such Solution, then nothing in this Agreement limits
      your or such Organization&rsquo;s obligations or PwC&rsquo;s rights under
      those Agreements with respect to such Solution, and those agreements will
      govern such Organization&rsquo;s use of (including access to) such
      Solution. References to &ldquo;<u>you</u>&rdquo; and &ldquo;<u>your</u>
      &rdquo; in this Agreement will refer to the individual using the Solution.
    </p>
    <p>
      This Agreement contains a mandatory arbitration provision that, as further
      set forth in Section 18 below, requires the use of arbitration on an
      individual basis to resolve disputes, rather than jury trials or any other
      court proceedings, or class actions of any kind.
    </p>
    <p>
      <strong>1. &nbsp;Changes.</strong> We may change this TOS and any Solution
      Terms from time to time by notifying you of such changes by any reasonable
      means, including by posting a revised TOS or Solution Terms through a
      Solution, or at magateway.pwc.com or any other applicable PwC website. No
      such changes will apply to any dispute between you and us arising before
      we posted the revised TOS or Solution Terms that contain such changes, or
      otherwise notified you of such changes.
    </p>
    <p>
      Your use of a Solution following any changes to this TOS or any applicable
      Solution Terms will constitute (a) your acceptance of such changes to this
      TOS, which will apply with respect to all Solutions; and (b)&nbsp;your
      acceptance of such changes to the Solution Terms, which will apply with
      respect to the corresponding Solution. The &ldquo;<em>Last Updated</em>
      &rdquo; legend above indicates when this TOS was last changed. We may, at
      any time and without liability, modify or discontinue all or part of a
      Solution (including access to any Solution via any third-party resources);
      charge, modify or waive any fees required to use any Solution; or offer
      opportunities to some or all Solution users.
    </p>
    <p>
      <strong>2. &nbsp;Information Submitted Through a Solution.</strong> Your
      submission of information through any Solution is governed by the Privacy
      Policy, as updated by us from time to time, currently located at
      https://www.pwc.com/us/en/site/privacy.html (the &ldquo;
      <u>Privacy Policy</u>&rdquo;). You represent and warrant that any
      information you provide in connection with any Solution is and will remain
      accurate and complete, and that you will maintain and update such
      information as needed.
    </p>
    <p>
      <strong>3. &nbsp;Jurisdictional Issues.</strong> The Solutions are
      controlled or operated (or both) from the United States, and are not
      intended to subject PwC to any non-U.S. jurisdiction or law. The Solutions
      may not be appropriate or available for use in some non-U.S.
      jurisdictions. Any use of a Solution is at your own risk, and you must
      comply with all applicable laws, rules and regulations in doing so. We may
      limit a Solution&rsquo;s availability at any time, in whole or in part, to
      any person, geographic area or jurisdiction that we choose.
    </p>
    <p>
      <strong>4. &nbsp;Rules of Conduct.</strong> In connection with any
      Solution, you must not:
    </p>
    <ul>
      <li>
        <span>
          Post, transmit or otherwise make available through or in connection
          with the Solution any information, data, content or other materials
          that are or may be: (a) threatening, harassing, degrading, hateful or
          intimidating, or otherwise fail to respect the rights and dignity of
          others; (b)&nbsp;defamatory, libelous, fraudulent or otherwise
          tortious; (c) obscene, indecent, pornographic or otherwise
          objectionable; or (d) protected by copyright, trademark, trade secret,
          right of publicity or privacy or any other proprietary right, without
          the express prior written consent of the applicable owner.
        </span>
      </li>
      <li>
        <span>
          Post, transmit or otherwise make available through or in connection
          with the Solution any virus, worm, Trojan horse, Easter egg, time
          bomb, spyware or other computer code, file or program that is or is
          potentially harmful or invasive or intended to damage or hijack the
          operation of, or to monitor the use of, any hardware, software or
          equipment (each, a &ldquo;<u>Virus</u>&rdquo;).
        </span>
      </li>
      <li>
        <span>
          Use the Solution for any commercial purpose, including using the
          Solution for or in connection with any facility management, service
          bureau or time-sharing purposes, services, or arrangements, or
          otherwise use the Solution for processing information, data, content
          or other materials on behalf of any third party.
        </span>
      </li>
      <li>
        <span>
          Use the Solution for any purpose that is fraudulent or otherwise
          tortious or unlawful.
        </span>
      </li>
      <li>
        <span>Harvest or collect information about users of the Solution.</span>
      </li>
      <li>
        <span>
          Interfere with or disrupt the operation of the Solution or the servers
          or networks used to make the Solution available, including by hacking
          or defacing any portion of the Solution; or violate any requirement,
          procedure or policy of such servers or networks.
        </span>
      </li>
      <li>
        <span>
          Interfere with or disrupt the integrity of any information, data,
          content or other materials available in or through the Solution.
        </span>
      </li>
      <li>
        <span>
          Restrict or inhibit any other person from using the Solution.
        </span>
      </li>
      <li>
        <span>
          Reproduce, modify, adapt, translate, create derivative works of, sell,
          rent, lease, loan, timeshare, distribute or otherwise exploit any
          portion of (or any use of) the Solution except as expressly authorized
          herein, without PwC&rsquo;s express prior written consent.
        </span>
      </li>
      <li>
        <span>
          Reverse engineer, decompile or disassemble any portion of the
          Solution, except where such restriction is expressly prohibited by
          applicable law.
        </span>
      </li>
      <li>
        <span>
          Remove or alter any copyright, trademark or other proprietary rights
          notice on the Solution.
        </span>
      </li>
      <li>
        <span>
          Frame or mirror any portion of the Solution, or otherwise incorporate
          any portion of the Solution into any product or service, without
          PwC&rsquo;s express prior written consent.
        </span>
      </li>
      <li>
        <span>
          Systematically download and store any information, data, content or
          other materials available in or through the Solution.
        </span>
      </li>
      <li>
        <span>
          Use any robot, spider, site search/retrieval application or other
          manual or automatic device to retrieve, index, &ldquo;scrape,&rdquo;
          &ldquo;data mine&rdquo; or otherwise gather any information, data,
          content or other materials available in or through the Solution, or
          reproduce or circumvent the navigational structure or presentation of
          the Solution, without PwC&rsquo;s express prior written consent.
        </span>
      </li>
    </ul>
    <p>
      You are responsible for obtaining, maintaining and paying for all hardware
      and all telecommunications and other services needed to use any Solution.
    </p>
    <p>
      <strong>5. &nbsp;Registration;</strong> No Sharing of User Name and
      Password. You may need to register or otherwise establish an account to
      use all or part of a Solution. We may reject, or require that you change,
      any user name, password or other information that you provide to us in
      registering or otherwise establishing an account. Your user name and
      password are for your personal use only and should be kept confidential.
      You must not provide your user name and password to any other person or
      entity for purposes of accessing or using any Solution.&nbsp; You, and not
      PwC, are responsible for any use or misuse of your user name or password,
      and you must promptly notify us of any confidentiality breach or
      unauthorized use of your user name or password, or your account.
    </p>
    <p>
      You must not, directly or indirectly, allow any other person or entity to
      use your account to access or use any Solution.
    </p>
    <p>
      <strong>6. &nbsp;Submissions.</strong> You and other users of a Solution
      may have the ability to make certain information, data, content or other
      materials (each, a &ldquo;<u>Submission</u>&rdquo;) available through or
      in connection with a Solution (for example, reports generated by using a
      Solution), including through a Solution&rsquo;s interactive services or
      sharing functionality. &nbsp;PwC has no control over and is not
      responsible for any use or misuse (including any distribution) by any
      third party of Submissions. If you choose to make any of your personally
      identifiable or other information publicly available through any Solution,
      you do so at your own risk.
    </p>
    <p>
      <strong>7. &nbsp;License. For purposes of clarity,</strong> you retain
      ownership of your Submissions. For each Submission, you hereby grant to us
      a worldwide, royalty-free, fully paid-up, non-exclusive, perpetual,
      irrevocable, transferable and fully sublicensable (through multiple tiers)
      license, without additional consideration to you or any third party, to
      reproduce, distribute, perform and display (publicly or otherwise), create
      derivative works of, adapt, modify and otherwise use, analyze, exploit and
      practice such Submission, in any format or media now known or hereafter
      developed, and for any purpose (including promotional purposes, such as
      testimonials).
    </p>
    <p>
      In addition, if you provide to us any ideas, proposals, suggestions or
      other information or materials (&ldquo;<u>Feedback</u>&rdquo;), whether
      related to a Solution or otherwise, such Feedback will be deemed a
      Submission, and you hereby acknowledge and agree that such Feedback is not
      confidential, and that your provision of such Feedback is gratuitous,
      unsolicited and without restriction, and does not place PwC under any
      fiduciary or other obligation.
    </p>
    <p>
      You represent and warrant that you have all rights necessary to grant the
      licenses granted in this Agreement, including in this Section, and that
      your Submissions, and your provision thereof through and in connection
      with any Solution, are complete and accurate, and are not fraudulent,
      tortious or otherwise in violation of any applicable law or any right of
      any third party. You further irrevocably waive any &ldquo;moral
      rights&rdquo; or other rights with respect to attribution of authorship or
      integrity of materials regarding each Submission that you may have under
      any applicable law under any legal theory.
    </p>
    <p>
      <strong>8. &nbsp;Monitoring.</strong> We may (but have no obligation to)
      monitor, evaluate, alter or remove Submissions before or after they appear
      on or through any Solution, including to comply with any requirements or
      prerequisites under the DMCA (as defined below). In addition, we may track
      or analyze your access to or use of any Solution. We may use and disclose
      to anyone (including third parties) information regarding your access to
      and use of any Solution, and the circumstances surrounding such access and
      use, for any reason or purpose, including in connection with performing
      services for you, in connection with operating any Solution, or for
      analytic, statistical, security, quality control, and similar purposes
      (including in aggregate form, e.g., to analyze systems performance).
    </p>
    <p>
      <strong>9. &nbsp;Your Limited Rights.</strong> None of the Solutions or
      Third Party Materials (as defined below) are sold to you. Your only rights
      in or to any Solutions or Third Party Materials are those (if any) that
      are granted to you under an express license in this Section or the
      applicable Solution Terms (and, with respect to Third Party Materials,
      under an applicable license between you and the corresponding third-party
      licensor).&nbsp; With respect to a particular Solution, subject to your
      compliance with this Agreement, and solely for so long as you are
      permitted by PwC to access and use the Solution:&nbsp; (a) with respect to
      any mobile software application component of the Solution (each, an
      &ldquo;<u>App</u>&rdquo;), we hereby permit you, on a limited,
      non-exclusive, revocable, non-transferable, non-sublicensable basis, to
      install and use the Solution on a mobile device that you own or control,
      solely for your personal, non-commercial use; and (b) with respect to any
      other component of the Solution, you may use any such portion of the
      Solution to which we provide you access under this Agreement, as viewable
      on any single device, solely for your personal, non-commercial use.&nbsp;
      If you fail to comply with any of the terms or conditions of this
      Agreement with respect to a particular Solution, you must immediately
      cease using the Solution and, with respect to any App, remove (that is,
      uninstall and delete) the Solution from your mobile device.
    </p>
    <p>
      <strong>10. &nbsp;PwC&rsquo;s Proprietary Rights.</strong> We and our
      suppliers own the Solutions, which are protected by proprietary rights and
      laws.&nbsp; As between you and us, the Solutions and all non-public
      information, data, content and other materials (technical, business and
      otherwise) related to any Solution (and the use of or access to it),
      including Submissions of other Solution users and any reports generated by
      using any Solution, are our &ldquo;<u>Confidential Information</u>&rdquo;
      and you will not use or disclose to any third party Confidential
      Information except to the extent necessary to exercise your rights and
      fulfill your obligations under this Agreement or as required by applicable
      law. &nbsp;All trade names, trademarks, service marks, logos and designs
      on any Solution not owned by us are the property of their respective
      owners. You may not use our trade names, trademarks, service marks, logos
      or designs (including &ldquo;PricewaterhouseCoopers&rdquo; and
      &ldquo;PwC&rdquo; and any associated logos, marks and designs) in any
      manner without our express prior written consent, including in connection
      with any product or service that is not ours, or in any manner that is
      likely to cause confusion. Nothing in this Agreement and nothing in or
      available through any Solution should be construed as granting any right
      to use any trade names, trademarks, service marks, logos or designs
      without the express prior written consent of the owner.
    </p>
    <p>
      <strong>11. &nbsp;</strong>
      <strong>CPA Notice; PwC Related Parties</strong>.&nbsp; PwC is owned by
      professionals who hold CPA licenses as well as by professionals who are
      not licensed CPAs.&nbsp; Depending on the nature of the products and
      services provided to you under this Agreement (including any Solution or
      through any Solution), non-CPA owners may be involved in providing such
      products and services.&nbsp; PwC is a firm in the global network of
      separate and independent PricewaterhouseCoopers firms (exclusive of PwC,
      the &ldquo;<u>Other PwC Firms</u>&rdquo;).&nbsp; PwC may draw on the
      resources of (and subcontract to) its affiliates, the Other PwC Firms and
      third party contractors and subcontractors, within or outside of the
      United States (each, a &ldquo;<u>PwC Service Provider</u>&rdquo;) for
      internal, administrative and regulatory compliance purposes or in
      connection with providing any Solution.&nbsp; The PwC Service Providers
      and their and PwC&rsquo;s respective partners, principals, employees and
      agents (collectively, the &ldquo;<u>PwC Beneficiaries</u>&rdquo;) will
      have no liability or obligations arising out of this Agreement, and you
      agree to bring any claim or other legal proceeding of any nature arising
      from or related to this Agreement or its subject matter against PwC and
      not against the PwC Beneficiaries.&nbsp; While PwC is entering into this
      Agreement on its own behalf, this Section 11 also is intended for the
      benefit of the PwC Beneficiaries.
    </p>
    <p>
      <strong>12. &nbsp;Third Party Materials; Links.</strong> Certain Solution
      functionality may make available access to products, services,
      information, data, content and other materials made available by third
      parties, including Submissions (&ldquo;<u>Third Party Materials</u>
      &rdquo;), or allow for the routing or transmission of Third Party
      Materials, including via links. By using such functionality, you are
      directing us to access, route and transmit to you the applicable Third
      Party Materials. In addition, your installation and other use of Third
      Party Materials may result in transmission of your information, data,
      content or other materials outside of our systems, or otherwise result in
      third-party access to or use of your information, data, content or other
      materials.
    </p>
    <p>
      We neither control nor endorse, nor are we responsible for, any Third
      Party Materials, including their accuracy, validity, timeliness,
      completeness, reliability, integrity, quality, legality, usefulness or
      safety, or any applicable intellectual property rights. Certain Third
      Party Materials may, among other things, be inaccurate, misleading or
      deceptive. Nothing in this Agreement will be deemed to be a representation
      or warranty by PwC with respect to any Third Party Materials. We have no
      obligation to monitor Third Party Materials, and we may block or disable
      access to any Third Party Materials (in whole or part) through any
      Solution at any time. In addition, the availability of any Third Party
      Materials through a Solution does not imply our endorsement of, or our
      affiliation with, any provider of such Third Party Materials, nor does
      such availability create any legal relationship between you and any such
      provider.
    </p>
    <p>
      Your use of Third Party Materials is at your own risk and is subject to
      any additional terms, conditions and policies applicable to such Third
      Party Materials (such as terms of service or privacy policies of the
      providers of such Third Party Materials).
    </p>
    <p>
      <strong>13. &nbsp;</strong>
      <strong>&nbsp;</strong>
      <strong>Open Source</strong>.&nbsp; The Solutions may include open source
      components, which are licensed for use and distribution by us under
      applicable open source licenses.&nbsp; Use of these open source components
      is governed by and subject to the terms and conditions of the applicable
      open source license.
    </p>
    <p>
      <strong>14. &nbsp;Disclaimer of Warranties.</strong> The Solutions
      (including any information and materials made available to you in
      connection with using any Solution) are not for any third party&rsquo;s
      use or benefit and are not intended to be relied upon by you or any third
      party, including to operate or promote your business, secure financing or
      capital in any form, sell or underwrite securities, obtain any regulatory
      or governmental approvals, or otherwise be used in connection with
      procuring services or other benefits from any entity.&nbsp; To the fullest
      extent permitted under applicable law: (a) the Solutions and any products,
      services and Third Party Materials are made available to you on an
      &ldquo;As Is,&rdquo; &ldquo;Where Is&rdquo; and &ldquo;Where
      Available&rdquo; basis, without any warranties of any kind, whether
      express, implied or statutory; and (b) PwC disclaims all warranties with
      respect to the Solution and any products, services and Third Party
      Materials, including the warranties of merchantability, fitness for a
      particular purpose, non-infringement and title. All disclaimers of any
      kind (including in this section and elsewhere in this Agreement) are made
      for the benefit of both PwC and the PwC Beneficiaries, and its and their
      respective partners, principals, directors, officers, employees, agents,
      representatives, licensors, suppliers and service providers (collectively,
      the &ldquo;<u>Affiliated Entities</u>&rdquo;), and their respective
      successors and assigns.
    </p>
    <p>
      While we try to maintain the timeliness, integrity and security of the
      Solutions, we do not guarantee that any Solution is or will remain
      updated, complete, accurate, correct or secure, or that access to any
      Solution will be uninterrupted. The Solutions may include inaccuracies,
      errors and materials that violate or conflict with this Agreement.
      Additionally, third parties may make unauthorized alterations to the
      Solutions. If you become aware of any such alteration, contact us at
      [E-MAIL ADDRESS] with a description of such alteration and its location.
    </p>
    <p>
      <strong>15. &nbsp;Limitation of Liability.</strong> To the fullest extent
      permitted under applicable law: (a) PwC will not be liable for any
      indirect, incidental, consequential, special, exemplary or punitive
      damages of any kind, under any contract, tort (including negligence),
      strict liability or other theory, including damages for loss of profits,
      use or data, loss of other intangibles, loss of security of Submissions
      (including unauthorized interception by third parties of any Submissions),
      even if advised in advance of the possibility of such damages or losses;
      (b) without limiting the foregoing, PwC will not be liable for damages of
      any kind resulting from your use of or inability to use any Solution or
      from any products, services or Third Party Materials, including from any
      Virus that may be transmitted in connection therewith; (c) your sole and
      exclusive remedy for dissatisfaction with any Solution or any products,
      services or Third Party Materials is to stop using the applicable
      Solution; and (d) the maximum aggregate liability of PwC for all damages,
      losses and causes of action, whether in contract, tort (including
      negligence) or otherwise, will be the greater of one hundred dollars
      ($100) and the total amount, if any, paid by you to PwC to use the
      applicable Solution to which the claim relates in the twelve (12) months
      preceding the date when such claim arose. &nbsp;All limitations of
      liability of any kind (including in this section and elsewhere in this
      Agreement) are made for the benefit of both PwC and the Affiliated
      Entities, and its and their respective successors and assigns.
    </p>
    <p>
      <strong>16. &nbsp;Indemnity.</strong> To the fullest extent permitted
      under applicable law, you will defend, indemnify and hold harmless PwC and
      each of the Affiliated Entities, and its and their respective successors
      and assigns, from and against all claims, liabilities, damages, judgments,
      awards, losses, costs, expenses and fees (including attorneys&rsquo; fees)
      arising out of or relating to (a) your use of, or activities in connection
      with, the Solutions (including all Submissions); and (b)&nbsp;any
      violation or alleged violation of this Agreement by you.
    </p>
    <p>
      <strong>17. &nbsp;Termination.</strong> This TOS and all Solution Terms
      will continue until terminated.&nbsp; PwC may immediately terminate this
      TOS or any Solution Terms without penalty or liability:&nbsp; (a) if
      continuing to perform under this TOS or such Solution Terms could, in
      PwC&rsquo;s sole and absolute judgment, result in PwC&rsquo;s
      noncompliance with any applicable law, rule or regulation, or any
      regulatory guidance, professional standard, or self-regulatory rule or
      policy, in each case as in effect from time to time; or (b) upon the
      occurrence of an event that, in PwC&rsquo;s sole and absolute judgment,
      causes or would be likely to cause PwC or any Other PwC Firm not to be
      &ldquo;independent&rdquo; as required by any law, rule, regulation or
      professional standard relating to independence. &nbsp;PwC may terminate or
      suspend your use of any Solution, any portion of any Solution, or all
      Solutions at any time and without prior notice, for any reason, including
      if PwC believes that you have violated or acted inconsistently with the
      letter or spirit of this Agreement. Immediately upon any such termination
      or suspension (or any termination of this TOS or the applicable Solution
      Terms), your right to use the applicable Solution(s) or portion of such
      Solution(s) will permanently or temporarily cease (as applicable), and PwC
      may, without liability to you or any third party, deactivate or delete
      your user name, password and account, and all associated information,
      data, content or other materials, without any obligation to provide any
      further access to such materials. &nbsp;If PwC terminates this TOS, then
      all Solution Terms and your rights with respect to all Solutions will
      automatically terminate. Sections 2 through 8 and 10 through 24 of this
      TOS will survive any termination of this TOS.
    </p>
    <p>
      <strong>18. &nbsp;Governing Law; Arbitration. This Agreement,</strong> and
      any dispute relating to this Agreement or the subject matter of this
      Agreement, will be governed by and construed, interpreted and enforced in
      accordance with the domestic laws of the State of New York, without giving
      effect to any provisions that would require the laws of another
      jurisdiction to apply and regardless of your location.&nbsp; The parties
      expressly disclaim the applicability of, and waive any rights based on,
      the Uniform Computer Information Transactions Act, the Uniform Commercial
      Code, or the United Nations Convention on Contracts for the International
      Sale of Goods, however each may be codified or amended.&nbsp; all disputes
      arising out of or related to this Agreement or the subject matter of this
      Agreement (including a Solution and the use thereof) or any aspect of the
      relationship between you and PwC under this Agreement, whether based in
      contract, tort, statute, fraud, misrepresentation or any other legal
      theory, will be resolved through final and binding arbitration before a
      neutral panel of three arbitrators instead of in a court by a judge or
      jury and you agree that PwC and you are each waiving the right to trial by
      a jury. You agree that any arbitration under this Agreement will take
      place on an individual basis; class arbitrations and class actions are not
      permitted and you are agreeing to give up the ability to participate in a
      class action.&nbsp; Notwithstanding the foregoing, nothing in this
      Agreement will preclude either party from seeking equitable relief in a
      court having jurisdiction.&nbsp; The arbitration will be conducted in
      accordance with the Rules for Non-Administered Arbitration of the
      International Institute for Conflict Prevention and Resolution then in
      effect (&ldquo;<strong>CPR Rules</strong>&rdquo;).&nbsp; The arbitration
      will be conducted before a panel of three arbitrators selected using the
      screened process provided for in the CPR Rules.&nbsp; The arbitration
      panel will have no power to award non-monetary or equitable relief of any
      sort, or to award damages inconsistent with this Agreement (including
      Section 16).&nbsp; Judgment on any arbitration award may be entered in any
      court having jurisdiction.&nbsp; All aspects of the arbitration will be
      treated as confidential.&nbsp; You acknowledge and agree that any demand
      for arbitration must be issued within one (1) year from the date that you
      became aware or should reasonably have become aware of the facts that give
      rise to PwC&rsquo;s alleged liability and in any event no later than two
      (2) years after the cause of action accrued.
    </p>
    <p>
      <strong>19. &nbsp;Filtering.</strong> We hereby notify you that parental
      control protections (such as computer hardware, software or filtering
      services) are commercially available that may assist you in limiting
      access to material that is harmful to minors. Current providers of such
      protections include Norton Family from Symantec Corporation (at
      https://family.norton.com) and Net Nanny (at https://www.netnanny.com),
      and information identifying current providers of such protections is
      accessible on the Internet (for example, at
      https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers).
      PwC does not endorse any of these products or services, including any that
      may be listed on the foregoing sites.
    </p>
    <p>
      <strong>20. &nbsp;Information &nbsp;or Complaints.</strong> If you have a
      question or complaint regarding any Solution, please send an e-mail to
      [E-MAIL ADDRESS]. Please note that e-mail communications will not
      necessarily be secure; accordingly you should not include credit card
      information or other sensitive information in your e-mail correspondence
      with us. California residents may reach the Complaint Assistance Unit of
      the Division of Consumer Services of the California Department of Consumer
      Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
      telephone at (916) 445-1254 or (800) 952-5210.
    </p>
    <p>
      <strong>21. &nbsp;Copyright Infringement Claims.</strong> If you believe
      in good faith that materials available on or through any Solution
      (including any applicable website for a Solution) infringe your copyright,
      the Digital Millennium Copyright Act of 1998 (the &ldquo;DMCA&rdquo;)
      provides a mechanism for you (or your agent) to send to PwC a written
      notice of such alleged infringement, requesting that PwC remove such
      material or block access to it. If you believe in good faith that someone
      has wrongly filed a notice of copyright infringement against you, the DMCA
      permits you to send to PwC a counter-notice. Notices and counter-notices
      must meet the DMCA&rsquo;s then-current statutory requirements. See{' '}
      <a href="http://www.copyright.gov/">
        <span>http://www.copyright.gov/</span>
      </a>{' '}
      for details. Notices and counter-notices must be sent in writing to
      PwC&rsquo;s DMCA agent as follows: By mail to PricewaterhouseCoopers LLP,
      Attn: DMCA Agent, Office of the General Counsel, Three Embarcadero Center,
      San Francisco, CA 94111; by e-mail to us_dmca@pwc.com; or by fax to the
      fax number that we will provide to you if you request such fax number by
      contacting PwC&rsquo;s DMCA agent by mail, e-mail or telephone at the
      addresses or phone number provided in this Section. PwC&rsquo;s DMCA
      agent&rsquo;s phone number is (415) 498-7542.
    </p>
    <p>
      You should consult your legal advisor before filing a DMCA notice or
      counter-notice.
    </p>
    <p>
      <strong>22. &nbsp;</strong>
      <strong>Trade Control Laws</strong>.&nbsp; The Solutions are subject to
      applicable export control laws and economic sanctions, including those of
      the United States and other relevant jurisdictions (&ldquo;
      <u>Trade Control Laws</u>&rdquo;).&nbsp; You are responsible for complying
      with all applicable Trade Control Laws and any violations of such Trade
      Control Laws. You represent, warrant and covenant as follows:&nbsp; (a)
      you will not use any Solution except as authorized by applicable Trade
      Control Laws; (b) you are not located in, or a resident or national of,
      any country subject to a U.S. government embargo or other restriction, or
      that has been designated by the U.S. government as a &ldquo;terrorist
      supporting&rdquo; country (&ldquo;<u>Sanctioned Countries</u>&rdquo;));
      (c) you will not access or otherwise use any Solution from or on behalf of
      any Sanctioned Country; (d) you are not and are not acting on behalf of a
      person or entity on any U.S. government list of restricted end-users or
      other applicable restricted party lists; (e) you will not use any Solution
      for any end-uses restricted by the U.S. government; and (f) you will not
      use any Solution to store or transfer any data subject to the
      International Traffic in Arms Regulations or subject to the Export
      Administration Regulations and controlled at a level other than EAR99/AT.
    </p>
    <p>
      <strong>23. &nbsp;Miscellaneous. This Agreement does not,</strong> and
      will not be construed to, create any partnership, joint venture,
      employer-employee, agency or franchisor-franchisee relationship between
      you and PwC. If any provision of this Agreement is found to be unlawful,
      void or for any reason unenforceable, that provision will be deemed
      severable from this Agreement and will not affect the validity and
      enforceability of any remaining provision. You may not assign, transfer or
      sublicense any or all of your rights or obligations under this Agreement
      without our express prior written consent. We may assign, transfer or
      sublicense any or all of our rights or obligations under this Agreement
      without restriction. No waiver by either party of any breach or default
      under this Agreement will be deemed to be a waiver of any preceding or
      subsequent breach or default. Any heading, caption or section title
      contained herein is for convenience only, and in no way defines or
      explains any section or provision. All terms defined in the singular will
      have the same meanings when used in the plural, where appropriate and
      unless otherwise specified. Any use of the term &ldquo;including&rdquo; or
      variations thereof in this Agreement will be construed as if followed by
      the phrase &ldquo;without limitation.&rdquo; This Agreement, including any
      terms and conditions incorporated herein, is the entire agreement between
      you and PwC relating to the subject matter hereof, and supersedes any and
      all prior or contemporaneous written or oral agreements or understandings
      between you and PwC relating to such subject matter. Notices to you
      (including notices of changes to this Agreement) may be made via posting
      on or through a Solution or by e-mail (including in each case via links),
      or by regular mail. Without limitation, a printed version of this
      Agreement and of any notice given in electronic form will be admissible in
      judicial or administrative proceedings based upon or relating to this
      Agreement to the same extent and subject to the same conditions as other
      business documents and records originally generated and maintained in
      printed form. PwC will not be responsible for any failure to fulfill any
      obligation due to any cause beyond its control.
    </p>
    <p>
      <strong>24. &nbsp;Apple-Specific Terms.</strong> In addition to your
      agreement with the foregoing terms and conditions, and notwithstanding
      anything to the contrary herein, the following provisions apply with
      respect to your use of any version of any App compatible with the iOS
      operating system of Apple Inc. (&ldquo;<u>Apple</u>&rdquo;). Apple is not
      a party to this Agreement and does not own and is not responsible for any
      App. Apple is not providing any warranty for any App except, if
      applicable, to refund the purchase price for it. Apple is not responsible
      for maintenance or other support services for any App and will not be
      responsible for any other claims, losses, liabilities, damages, costs or
      expenses with respect to any App, including any third-party product
      liability claims, claims that any App fails to conform to any applicable
      legal or regulatory requirement, claims arising under consumer protection
      or similar legislation, and claims with respect to intellectual property
      infringement. Any inquiries or complaints relating to the use of any App,
      including those pertaining to intellectual property rights, must be
      directed to PwC in accordance with the &ldquo;
      <em>Information or Complaints</em>&rdquo; section above. The license you
      have been granted in this Agreement for any App compatible with
      Apple&rsquo;s iOS operating system is limited to a non-transferable
      license to use such App on an Apple-branded product that runs
      Apple&rsquo;s iOS operating system and is owned or controlled by you, or
      as otherwise permitted by the Usage Rules set forth in Apple&rsquo;s App
      Store Terms of Service, except that the App may also be accessed and used
      by other accounts associated with you via Apple&rsquo;s Family Sharing or
      volume purchasing programs. In addition, you must comply with the terms of
      any third-party agreement applicable to you when using any App, such as
      your wireless data service agreement. Apple and Apple&rsquo;s subsidiaries
      are third-party beneficiaries of this Agreement and, upon your acceptance
      of the terms and conditions of this Agreement, will have the right (and
      will be deemed to have accepted the right) to enforce this Agreement
      against you as a third-party beneficiary thereof; notwithstanding the
      foregoing, PwC&rsquo;s right to enter into, rescind or terminate any
      variation, waiver or settlement under this Agreement is not subject to the
      consent of any third party.
    </p>
    <p>&nbsp;</p>
    <br />
    <br />
  </>
);

const Terms = () => (
  <div className="privacy-p">
    <TermsContent />
  </div>
);

export default Terms;
