import { handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import { ProjectActionsEnum, ProjectReducerState } from '../types';
import { SectorInfo } from '@models';

const defaultState: ProjectReducerState = {
  sectorsInfo: [],
  sectorsInfoWithoutX: [],
  isSectorsLoading: true,
  isUserLoading: true,
  isSectorsWithoutXLoading: true,
  isProjectCreateLoading: false,
  redirectToList: false,
  projectsInfo: [],
  isProjectsLoading: true,
  userInfoByGuid: {},
  isCustomizedFieldModelLoading: true,
  customizedFieldModel: [],
};

export default handleActions(
  {
    [ProjectActionsEnum.GET_SECTOR_INFO_PENDING]: (
      state: ProjectReducerState,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isSectorsLoading = true;
      }),
    [ProjectActionsEnum.GET_SECTOR_INFO_FULFILLED]: (
      state: ProjectReducerState,
      action: Action<SectorInfo[]>,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isSectorsLoading = false;
        draft.sectorsInfo = action.payload || [];
      }),
    [ProjectActionsEnum.GET_USER_INFO_PENDING]: (state: ProjectReducerState) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isUserLoading = true;
      }),
    [ProjectActionsEnum.GET_USER_INFO_FULFILLED]: (
      state: ProjectReducerState,
      action: Action<any>,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isUserLoading = false;
        draft.userInfoByGuid = action.payload[0] || {};
      }),
    [ProjectActionsEnum.GET_SECTOR_INFO_WITHOUT_X_PENDING]: (
      state: ProjectReducerState,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isSectorsWithoutXLoading = true;
      }),
    [ProjectActionsEnum.GET_SECTOR_INFO_WITHOUT_X_FULFILLED]: (
      state: ProjectReducerState,
      action: Action<SectorInfo[]>,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        const sectors = action.payload || [];
        draft.isSectorsWithoutXLoading = false;
        draft.sectorsInfoWithoutX = sectors
          .filter((sector) => !sector.sectorName.includes('X-'))
          .map((sector) => {
            sector.subsectors = (sector.subsectors || []).filter(
              (subsector) => !subsector.subsectorName.includes('X-'),
            );
            return sector;
          });
      }),
    [ProjectActionsEnum.CREATE_PROJECT_PENDING]: (state: ProjectReducerState) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isProjectCreateLoading = true;
      }),
    [ProjectActionsEnum.CREATE_PROJECT_FULFILLED]: (
      state: ProjectReducerState,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isProjectCreateLoading = false;
        draft.redirectToList = true;
      }),
    [ProjectActionsEnum.CLEAR_LOADING_REDIRECT_STATES]: (
      state: ProjectReducerState,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isSectorsLoading = true;
        draft.isProjectCreateLoading = false;
        draft.redirectToList = false;
      }),

    [ProjectActionsEnum.GET_PROJECT_INFO_PENDING]: (
      state: ProjectReducerState,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isProjectsLoading = true;
      }),
    [ProjectActionsEnum.GET_PROJECT_INFO_FULFILLED]: (
      state: ProjectReducerState,
      action: Action<[]>,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isProjectsLoading = false;
        draft.projectsInfo = action.payload || [];
      }),

    [ProjectActionsEnum.GET_CUSTOMIZED_MODEL_PENDING]: (
      state: ProjectReducerState,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isCustomizedFieldModelLoading = true;
      }),
    [ProjectActionsEnum.GET_CUSTOMIZED_MODEL_FULFILLED]: (
      state: ProjectReducerState,
      action: Action<any>,
    ) =>
      produce(state, (draft: ProjectReducerState) => {
        draft.isCustomizedFieldModelLoading = false;
        draft.customizedFieldModel = action.payload || {};
      }),
  },
  defaultState,
);
