import { createActions } from 'redux-actions';
import { breadCrumbMap } from '@utils';
import { CommonActionsEnum } from '../types';
import compact from 'lodash/compact';

let currentPathTemp = '';
const commonActions = createActions(
  {},
  CommonActionsEnum.CHANGE_BREADCRUMB,
  CommonActionsEnum.CHANGE_CURRENT_PROJECT_ID,
);

const matchBreadCrumb = () => {
  const currentPath = window.location.pathname;

  if (currentPathTemp === currentPath) {
    return null;
  }

  const newBreadCrumb = breadCrumbMap.map((item) => {
    if (currentPath.includes(item.path)) {
      return item;
    }

    const splitPath: string[] = item.path.split('/') || [];
    let matchCount = 0;
    splitPath.forEach((splitPath: string) => {
      if (currentPath.includes(splitPath)) {
        matchCount += 1;
      }
    });

    if (matchCount >= splitPath.length) {
      return item;
    }

    return null;
  });

  currentPathTemp = currentPath;
  return commonActions.changeBreadcrumb(compact(newBreadCrumb));
};

const changeCurrentProjectId = (id: string): any =>
  commonActions.changeCurrentProjectId(id);

export const commonAction = {
  matchBreadCrumb,
  changeCurrentProjectId,
};
