import '../../styles/components/alert.scss';
// import { getTokenFromCode } from '@services';
import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import { requestAccess } from '@services/user.service';

const REQUEST_ACCESS = 'Request Access';
const REQUEST_REASON = 'Request Reason';

const { PopAlert } = require('appkit-react');

const RequestAccessModal = ({
  userInfo,
  requestApp,
  AfterClose,
}: {
  userInfo: any;
  requestApp: string | null;
  AfterClose: () => void;
}) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [reason, setReason] = useState('');

  const sendRequest = async () => {
    setIsRequesting(true);
    await requestAccess(userInfo, requestApp || '', reason);
    setIsRequesting(false);
    AfterClose();
    setReason('');
    PopAlert({
      oneLineDisplay: true,
      duration: 2000,
      status: 'success',
      message: 'Request has been sent successfully',
      className: 'popup-success',
    });
  };

  const onCancel = () => {
    setReason('');
    AfterClose();
  };

  return (
    <Modal
      visible={requestApp !== null}
      title={REQUEST_ACCESS}
      onOk={sendRequest}
      okButtonProps={reason.length === 0 ? { disabled: true } : {}}
      onCancel={onCancel}
      okText="Request"
      // footer={
      //   <div>
      //     <Button type="primary" onClick={sendRequest}>
      //       Request Access
      //     </Button>
      //   </div>
      // }
      confirmLoading={isRequesting}
      className="request-reason-modal"
    >
      {/* <div>
        Ask for access to M&A Gateway, or contact our support team via email for
        any questions - <a
          href="mailto:us_dt_solution_innovation@pwc.com"
          target="_blank"
          rel="noreferrer"
        >
          us_dt_solution_innovation@pwc.com
        </a>
      </div> */}
      <div>{REQUEST_REASON}</div>
      <Input.TextArea
        className="reason-input"
        placeholder={REQUEST_REASON}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        maxLength={500}
      />
    </Modal>
  );
};

export default RequestAccessModal;
