import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import { LoginActionsEnum, LoginReducerState } from '../types';

// test code
const userInfoString = window.localStorage.getItem('userInfo') || '{}';
const token = window.localStorage.getItem('accessToken');
const userInfo = JSON.parse(userInfoString);
const defaultState: LoginReducerState = {
  userInfo,
  isLogin: userInfo && userInfo !== '{}' && token ? true : false,
};

export default handleActions(
  {
    [LoginActionsEnum.SAVE_USER_INFO]: (
      state: LoginReducerState,
      action: any,
    ) =>
      produce(state, (draft) => {
        draft.userInfo = action.payload;
        if (Object.keys(draft.userInfo).length === 0) {
          draft.isLogin = false;
        } else {
          draft.isLogin = true;
        }
      }),
  },
  defaultState,
);
