import Axios, { AxiosRequestConfig } from 'axios';
import { ERROR_CODE, NETWORK_ERROR } from '@models';
import { clearLocalStorage } from '@utils';
// import store from '@src/redux/store';
import '@styles/components/popup.scss';

const { PopAlert } = require('appkit-react');

const baseURL = process.env.REACT_APP_service_url;
const axios = Axios.create({
  baseURL,
});

const Alert = (message?: string) => {
  PopAlert({
    oneLineDisplay: true,
    duration: 3000,
    status: 'error',
    message: message || NETWORK_ERROR.INTERNAL_SERVER_ERROR,
    closeable: true,
    className: 'popup-failed',
  });
  setTimeout(() => {
    try {
      const targetEle = document.querySelector('.a-alert-pop-wrapper');
      const removeEle = document.querySelector('.a-alert-pop-wrapper');
      if (targetEle && targetEle.parentNode && removeEle) {
        targetEle.parentNode.removeChild(removeEle);
      }
    } catch (e) {
      /* tslint:disable-next-line */
      console.error(e);
    }
  }, 3000);
};

// request interceptor
axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.url && !config.url.includes('http')) {
      const token = window.localStorage.getItem('accessToken');
      const refreshToken = window.localStorage.getItem('refreshToken');
      // const origin = window.localStorage.getItem('origin');
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.refreshToken = `${refreshToken}`;
      config.headers['auth-origin'] = origin;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// response interceptor
axios.interceptors.response.use(
  (response) => {
    const result = response?.data?.data || response?.data || {};

    response.headers?.token &&
      window.localStorage.setItem('accessToken', response.headers.token);
    response.headers?.refreshtoken &&
      window.localStorage.setItem('refreshToken', response.headers.refreshtoken);

    return result;
  },
  
  (error) => {
    const isRequestingCode =
      error.response?.config?.url?.indexOf('loginToken') !== -1;
    if (error.response?.status === ERROR_CODE.NOT_ACCEPTABLE) {
      return null;
    }
    if (
      error.response?.status !== ERROR_CODE.FORBIDDEN_ERROR &&
      !window.location.pathname.includes('bulkedit') &&
      !isRequestingCode
    ) {
      // 根据Gabble、Richard、Chris的会上的建议先全注释
      // Alert();
    }
    if (
      error.response?.status === ERROR_CODE.FORBIDDEN_ERROR &&
      error.response?.config?.url === '/api/project/list'
    ) {
      clearLocalStorage();
      // Alert();
      window.location.pathname = '/login';
      return null;
    }

    if (error.message === ERROR_CODE.NETWORK_ERROR) {
      return null;
    }
    switch (error.response.status) {
      case ERROR_CODE.ACCESS_ERROR:
        clearLocalStorage();
        window.location.pathname = '/login';
        break;
      case ERROR_CODE.FORBIDDEN_ERROR:
        if (isRequestingCode) {
          return error.response.data;
        }
        window.location.pathname = '/';
        break;
      default:
        break;
    }
    return null;
  },
);
export { axios };
