import React, { forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { DropdownOption } from '@models';
import '@styles/components/selectCheckbox.scss';

interface InputSingleSelectProps {
  value?: SelectValue;
  defaultValue?: SelectValue;
  options?: DropdownOption[];
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

const InputSingleSelect = (props: InputSingleSelectProps, ref: any) => {
  const [inputValue, setInputValue] = useState<string>();
  const [value, setValue] = useState<SelectValue>(props.defaultValue || '');
  const [options, setOptins] = useState<DropdownOption[]>(props.options || []);

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  useEffect(() => {
    setOptins(props.options || []);
  }, [props.options]);

  const onChange = (e: any) => {
    setValue(e.target ? e.target : e);
    props.onChange && props.onChange(e);
  };

  const onInputKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValue) {
        onChange(inputValue);
        if (!_.find(options, { value: inputValue.toLowerCase() })) {
          setOptins([
            ...(options || []),
            { label: inputValue, value: inputValue },
          ]);
        }
      }
    }
  };

  return (
    <Select
      ref={ref}
      className="dropdown"
      showSearch
      style={{ width: '100%' }}
      value={value}
      defaultValue={props.defaultValue}
      onChange={onChange}
      onSearch={setInputValue}
      options={options}
      inputValue={inputValue}
      onInputKeyDown={onInputKeyDown}
      onBlur={props.onBlur}
      getPopupContainer={(trigger) => trigger.parentElement}
    />
  );
};

export default forwardRef(InputSingleSelect);
