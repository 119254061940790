import {
  getProjectFunctionsRequest,
  saveProjectFunctionRequest,
  getTemplateListRequest,
} from '@services';
import { createActions } from 'redux-actions';
import { ProjectFunction, ProjectFunctionSaveParams } from '@models';
import { ProjectFunctionActionsEnum } from '../types';

const projectFunctionActions = createActions(
  {},
  ProjectFunctionActionsEnum.GET_PROJECT_FUNCTIONS,
  ProjectFunctionActionsEnum.GET_PROJECT_FUNCTIONS_PENDING,
  ProjectFunctionActionsEnum.GET_PROJECT_FUNCTIONS_FULFILLED,
  ProjectFunctionActionsEnum.CHANGE_PROJECT_FUNCTIONS,
  ProjectFunctionActionsEnum.RESET_PROJECT_FUNCTIONS,
  ProjectFunctionActionsEnum.SAVE_PROJECT_FUNCTION,
  ProjectFunctionActionsEnum.SAVE_PROJECT_FUNCTION_PENDING,
  ProjectFunctionActionsEnum.SAVE_PROJECT_FUNCTION_FULFILLED,
  ProjectFunctionActionsEnum.GET_ALL_TEMPLATE_LIST,
  ProjectFunctionActionsEnum.GET_ALL_TEMPLATE_LIST_PENDING,
  ProjectFunctionActionsEnum.GET_ALL_TEMPLATE_LIST_FULFILLED,
);

const getProjectFunctions = (projectId: number): any =>
  projectFunctionActions.getProjectFunctions(
    getProjectFunctionsRequest(projectId),
  );
const changeProjectFunctions = (functions: ProjectFunction[]): any =>
  projectFunctionActions.changeProjectFunctions(functions);

const resetProjectFunctions = (): any =>
  projectFunctionActions.resetProjectFunctions();

const saveProjectFunction = (params: ProjectFunctionSaveParams): any =>
  projectFunctionActions.saveProjectFunction(
    saveProjectFunctionRequest(params),
  );

const getAllTemplateList = (): any =>
  projectFunctionActions.getAllTemplateList(getTemplateListRequest());

export const projectFunctionAction = {
  getProjectFunctions,
  changeProjectFunctions,
  resetProjectFunctions,
  saveProjectFunction,
  getAllTemplateList,
};
