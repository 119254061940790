import { Moment } from 'moment';

export interface SubSectorInfo {
  subsectorId: number;
  subsectorName: string;
}

export interface SectorInfo {
  sectorId: number;
  sectorName: string;
  subsectorId?: number; // remove?
  subsectorName?: string; // remove?
  subsectors?: SubSectorInfo[];
}

export enum RepresentedRoleEnum {
  BUYER = 'Buyer',
  SELLER = 'Seller',
}

export interface ProjectInfo {
  id: number;
  name: string;
  pwcRepresentedRole: RepresentedRoleEnum;
  divestitureEntity: string;
  seller: string;
  annualizedCost: number;
  sectorName: string;
  subsectorName: string;
  dealValue: number;
  divestitureEntityRevenue: number;
  targetCloseDate: string;
  creatorId: number;
  sectorId: number;
  subsectorId: number;
  createdAt: string;
  updatedAt: string;
  entangleCount: number;
  isConfidential: boolean;
  buyer?: string;
  expectedAnnounDate?: string;
}

export interface ProjectInput {
  name: string;
  pwcRepresentedRole: string;
  divestitureEntity: string;
  seller: string;
  targetCloseDate: string;
  sectorId: number;
  subsectorId: number | string;
  isConfidential?: boolean;
  buyer?: string;
  expectedAnnounDate?: string;
  dealValue?: number;
  divestitureEntityRevenue?: number;
  partners: any[];
  directors?: any[];
  members?: any[];
}

export interface ProjectInputDefault {
  name?: string;
  pwcRepresentedRole?: string;
  divestitureEntity?: string;
  seller?: string;
  targetCloseDate?: string;
  sectorId?: number;
  subsectorId?: number | string;
  isConfidential?: boolean;
  buyer?: string;
  expectedAnnounDate?: string;
  partners?: any[];
  directors?: any[];
  members?: any[];
}

export interface ProjectFormValue {
  name: string;
  pwcRepresentedRole: string;
  divestitureEntity: string;
  seller: string;
  sectorId: number;
  subsectorId: number;
  isConfidential?: boolean;
  buyer?: string;
  targetCloseDate: Moment;
  expectedAnnounDate?: Moment;
}

export interface ProjectfunctionStatus {
  functionName?: string;
  subfunctionName?: string;
  stage?: string;
  uniqueSubfunctionCount?: number | null;
  entanglementCount: number | null;
  tsaCount: number | null;
  rtsaCount: number | null;
  tsaMonthlyCost: number | null;
  rtsaMonthlyCost: number | null;
  separation: number | null;
  tsaSetup: number | null;
  tsaExit: number | null;
  functions?: ProjectfunctionStatus[];
  subfunctions?: ProjectfunctionStatus[];
}
export interface UserInfoByGuid {
  guid?: string;
  exp?: number;
  fullName?: string;
  email?: string;
  title?: string;
  country?: string;
  location?: string;
  department?: string;
}

export interface CustomizedFieldModel {
  id?: number;
  entanglementId?: number | null | undefined;
  isEntanglement?: boolean;
  isEnt?: boolean;
  isTom?: boolean;
  isTsa?: boolean;
  name?: string;
  mapping?: string;
  fieldName?: string;
  content?: string;
  projectId?: number;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ManageFieldModel {
  id?: string | number;
  fieldName: string;
  type: 'text' | 'numeric';
  projectId: number;
  order: number;
  mapping: string;
  isEdit: boolean;
  isEnt: boolean;
  isTom: boolean;
  isTsa: boolean;
  isReportingField: boolean;
  isCustomizedField: boolean;
  isRequiredEnt?: boolean;
  isRequiredTom?: boolean;
  isRequiredTsa?: boolean;
  [key: string]: any;
}
