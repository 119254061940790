import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { CollapseContent, CollapseSelectItems } from '@models';
import { Checkbox, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { omit } from 'lodash';
import '@styles/components/collapsePanel.scss';

const {
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} = require('appkit-react');

interface CollapsePanelInterface {
  panelContents: CollapseContent[];
  className?: string;
  activeIds?: string[];
  panelChange?: (activeItems: string[]) => void;
  selectItems?: (selectItems: CollapseSelectItems) => void;
  radioSelectItem?: (selectedValue: number) => void;
  isReset?: { isCollapse: boolean; isClearCheckbox: boolean };
}

let selectedGroup: { [key: string]: string[] } = {};
let selectedParentIds: CheckboxValueType[] = [];
let selectedChildIds: CheckboxValueType[] = [];

function CollapsePanel(props: CollapsePanelInterface) {
  const {
    panelContents,
    activeIds = [],
    className = '',
    panelChange,
    selectItems,
    radioSelectItem,
    isReset,
  } = props;
  const { isCollapse = false, isClearCheckbox = false } = isReset || {};

  const [activeItems, setActiveItems] = useState<string[]>(activeIds);
  const [checkedChildIds, setCheckedChildren] = useState<CheckboxValueType[]>(
    [],
  );
  const [checkedRadioValue, setCheckedRadioValue] = useState<number>();

  const updatePropsItems = useCallback(() => {
    if (selectItems) {
      selectItems({
        selectedParentIds,
        selectedChildIds,
        selectedGroup,
      });
    }
  }, [selectItems]);

  const clearCheckBox = useCallback(
    (type: string) => {
      if (type === 'collapse') {
        setActiveItems([]);
      }
      setCheckedChildren([]);
      selectedGroup = {};
      selectedParentIds = [];
      selectedChildIds = [];
      updatePropsItems();
    },
    [updatePropsItems],
  );

  useEffect(() => {
    if (isCollapse && !isClearCheckbox) {
      clearCheckBox('collapse');
    } else if (isClearCheckbox && !isCollapse) {
      clearCheckBox('clearCheckbox');
    } else {
      clearCheckBox('');
    }
  }, [isCollapse, isClearCheckbox, clearCheckBox]);

  useEffect(
    () => () => {
      clearCheckBox('');
    },
    [clearCheckBox],
  );

  useEffect(() => {
    if (activeIds.length > 0) {
      setActiveItems(activeIds);
    }
  }, [panelContents, activeIds]);

  useEffect(() => {
    const parentIds: CheckboxValueType[] = [];
    const childIds: CheckboxValueType[] = [];

    panelContents.forEach((panelContent: CollapseContent) => {
      let childItemLength = 0;
      panelContent.collapseBody &&
        panelContent.collapseBody.contents &&
        panelContent.collapseBody.contents.forEach((content) => {
          if (selectedChildIds.includes(String(content.value))) {
            childIds.push(String(content.value));
            childItemLength += 1;
            if (
              childIds.length &&
              panelContent.collapseBody &&
              childItemLength >= panelContent.collapseBody.contents.length
            ) {
              parentIds.push(String(panelContent.id));
            }
          }
        });
    });

    selectedParentIds = parentIds;
    selectedChildIds = childIds;

    setCheckedChildren(selectedChildIds);
    updatePropsItems();
  }, [panelContents, updatePropsItems, setCheckedChildren]);

  const changePanel = (selectedId: string) => {
    let items = [...activeItems];
    if (items.includes(selectedId)) {
      items = items.filter((item) => item !== selectedId);
    } else {
      items.push(selectedId);
    }
    setActiveItems(items);
    if (panelChange) {
      panelChange(items);
    }
  };

  const setSelectedGroup = (
    checked: boolean,
    parentId: string,
    value: string[],
    isSingleSelection: boolean,
  ) => {
    if (isSingleSelection) {
      selectedGroup = { [parentId]: value };
      return;
    }
    if (checked) {
      selectedGroup = { ...selectedGroup, [parentId]: value };
    } else {
      selectedGroup = omit(selectedGroup, parentId);
    }
  };

  const checkPanelChildren = (
    selectedIds: CheckboxValueType[],
    panelContent: CollapseContent,
    isSingleSelection: boolean = false,
  ) => {
    const parentId = String(panelContent.id);
    const contentIds = selectedIds.map((selectedId) => String(selectedId));
    const checked = Boolean(selectedIds.length);
    setSelectedGroup(checked, parentId, contentIds, isSingleSelection);

    const actualContentLength = panelContent.collapseBody?.contents.length;
    const isSelectAll = actualContentLength === contentIds.length;
    if (isSelectAll) {
      isSingleSelection
        ? (selectedParentIds = [parentId])
        : selectedParentIds.push(parentId);
    } else {
      selectedParentIds = selectedParentIds.filter(
        (selectedParentId) => selectedParentId !== parentId,
      );
    }
    selectedChildIds = [];
    for (const parentId in selectedGroup) {
      selectedChildIds = selectedChildIds.concat(selectedGroup[parentId]);
    }
    setCheckedChildren(selectedChildIds);
    updatePropsItems();
  };

  const checkPanelHeader = (
    checkboxEvent: CheckboxChangeEvent,
    panelContent: CollapseContent,
  ) => {
    const { checked } = checkboxEvent.target;
    const contents = panelContent.collapseBody?.contents || [];
    const contentIds = checked
      ? contents.map((content) => String(content.value))
      : [];
    const isSingleSelection = Boolean(
      panelContent.collapseHeader?.isSingleSelection,
    );
    checkPanelChildren(contentIds, panelContent, isSingleSelection);
  };

  const buildItemHeader = (panelContent: CollapseContent) => {
    const { id, header, collapseHeader } = panelContent;
    const parentId = id;
    const headerChecked = selectedParentIds.some(
      (selectedParentId) => String(selectedParentId) === String(parentId),
    );
    let itemHeader;
    if (header) {
      itemHeader = header;
    } else if (collapseHeader) {
      // TODO: selectionType: checkbox or radio, add radio
      const {
        text,
        selectable,
        defaultChecked,
        // selectionType = 'checkbox',
      } = collapseHeader;
      itemHeader = selectable ? (
        <Checkbox
          defaultChecked={defaultChecked}
          checked={headerChecked}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
          onChange={(checkboxEvent) =>
            checkPanelHeader(checkboxEvent, panelContent)
          }
        >
          <span
            onClick={(event: any) => {
              event.preventDefault();
            }}
          >
            {text}
          </span>
        </Checkbox>
      ) : (
        <span>{text}</span>
      );
    }
    return itemHeader;
  };

  const buildItemBody = (panelContent: CollapseContent) => {
    const { children, collapseBody } = panelContent;
    let itemBody;
    if (children) {
      itemBody = children;
    } else if (collapseBody) {
      // TODO: selectionType: checkbox or radio, add radio
      const {
        selectable,
        defaultValue,
        contents,
        selectionType,
      } = collapseBody;

      const options = contents.map((content, index) => ({
        label: content.label,
        value: String(content.value || index),
      }));

      const handleRadioCheck = (e: any) => {
        // console.log('radio checked: ', e.target.value);
        setCheckedRadioValue(e.target.value);
        if (radioSelectItem) {
          radioSelectItem(e.target.value);
        }
      };

      itemBody = selectable ? (
        selectionType !== 'radio' ? (
          <Checkbox.Group
            options={options}
            defaultValue={defaultValue}
            value={checkedChildIds}
            onChange={(selectedIds) => {
              checkPanelChildren(selectedIds, panelContent);
            }}
          />
        ) : (
          <Radio.Group
            options={options}
            value={checkedRadioValue}
            onChange={handleRadioCheck}
          />
        )
      ) : (
        contents.map((content) => (
          <div key={content.value} className="inner-children-item">
            <div>{content.label}</div>
          </div>
        ))
      );
    }
    return itemBody;
  };
  const panelItem: ReactNode = panelContents.map((panelContent, index) => {
    const itemHeader = buildItemHeader(panelContent);
    const itemBody = buildItemBody(panelContent);

    return (
      <AccordionItem key={panelContent.id} itemId={String(panelContent.id)}>
        <AccordionItemHeader className="a-font-16">
          <div className="flex-row-start">{itemHeader}</div>
        </AccordionItemHeader>
        <AccordionItemBody>{itemBody}</AccordionItemBody>
      </AccordionItem>
    );
  });

  return (
    <Accordions
      multiOpen
      className={`accordion-wrapper ${className}`}
      activeItem={activeItems}
      onChange={(itemId: string) => changePanel(itemId)}
    >
      {panelItem}
    </Accordions>
  );
}

export default CollapsePanel;
