import React, { ReactNode } from 'react';
import { Card as AntdCard } from 'antd';
import '@styles/components/card.scss';

interface CardProps {
  children: ReactNode;
  className?: string;
}

function Card(props: CardProps) {
  const { children, className } = props;
  return (
    <AntdCard className={`card-wrapper ${className || ''}`} bordered={false}>
      {children}
    </AntdCard>
  );
}

export default Card;
