import { ProjectInput } from '@models';
import {
  createProjectRequest,
  getSectorInfoRequest,
  getProjectsInfoRequest,
  getUserInfoByGuidRequest,
  getCustomizedFieldModelRequest,
} from '@services';
import { createActions } from 'redux-actions';
import { ProjectActionsEnum } from '../types';

const projectActions = createActions(
  {},
  ProjectActionsEnum.GET_SECTOR_INFO,
  ProjectActionsEnum.GET_SECTOR_INFO_WITHOUT_X,
  ProjectActionsEnum.CREATE_PROJECT,
  ProjectActionsEnum.CLEAR_LOADING_REDIRECT_STATES,
  ProjectActionsEnum.GET_PROJECT_INFO,
  ProjectActionsEnum.GET_USER_INFO,
  ProjectActionsEnum.GET_CUSTOMIZED_MODEL,
);

const getSector = (): any =>
  projectActions.getSectorInfo(getSectorInfoRequest());

const getSectorWithoutX = (): any =>
  projectActions.getSectorInfoWithoutX(getSectorInfoRequest());

const createProject = (projectInput: ProjectInput): any =>
  projectActions.createProject(createProjectRequest(projectInput));
const clearLoadingRedirectStates = (): any =>
  projectActions.clearLoadingRedirectStates();

const getProjects = (): any =>
  projectActions.getProjectInfo(getProjectsInfoRequest());

const getUserByGuid = (guid: string): any =>
  projectActions.getUserInfo(getUserInfoByGuidRequest(guid));

const getCustomizedFieldModel = (projectId: number): any =>
  projectActions.getCustomizedModel(getCustomizedFieldModelRequest(projectId));

export const projectAction = {
  getSector,
  getSectorWithoutX,
  createProject,
  clearLoadingRedirectStates,
  getProjects,
  getUserByGuid,
  getCustomizedFieldModel,
};
