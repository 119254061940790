import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router';
import { RootStoreType } from '@reducer';
// import { commonAction } from '@action';
import { routes, checkLogin } from '@utils';
import { Loading } from '@components';
import { RouteType } from '@models';
import AppHeader from './AppHeader';
import AppFooter from '@components/AppFooter';
// import AppSideBar from './AppSideBar';
import find from 'lodash/find';
import lodashMap from 'lodash/map';
import classnames from 'classnames';
import '@styles/layout.scss';


const mapStoreToProps = (store: RootStoreType) => ({
  isLogin: store.login.isLogin,
  isLoading: store.common.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({});

const connector = connect(mapStoreToProps, mapDispatchToProps);

interface AppContainerProps
  extends ConnectedProps<typeof connector>,
    RouteComponentProps {
  isLoading: boolean;
}
function AppContainer(props: AppContainerProps) {
  const isSelected = true;
  const isAdmin = false;
  const {
    isLogin,
    isLoading,
    match,
    // currentProjectId,
    // toggleBreadcrumb,
    location,
  } = props;
  const { pathname = '' } = location;
  // const routes = getRoutes(isSelected, isAdmin);

  const getCurrentRoute = (): RouteType => {
    const pathArr = pathname.split('/');
    const subPathName = pathArr.length ? pathArr[pathArr.length - 1] : '';
    return find(routes, { path: subPathName }) || {};
  };
  const getLabel = (): string => {
    const currentRoute = getCurrentRoute();
    const { path } = currentRoute || routes[0];
    return path || '';
  };

  useEffect(() => {
    const loginProgress = window.sessionStorage.getItem('loginProgress');
    if (!isLogin && loginProgress) {
      window.location.pathname = '/login';
    }
  }, [isLogin]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <div
        className={classnames({
          'app-container': true,
          'app-filter': isLoading,
        })}
      >
        <AppHeader routes={routes} basePath={match.path} label={getLabel()} />
        <main className="app-body">
          <div className="app-content">
            <Switch>
              {lodashMap(routes, (route, i) => {
                const { path, main: Main, disabled, routes: mapRoute } = route;

                if (disabled) {
                  return null;
                }

                return (
                  <Route
                    key={i}
                    path={`${match.path}${path}`}
                    render={(props) => <Main {...props} routes={mapRoute} />}
                  />
                );
              })}
              <Redirect path="*" to="/" />
            </Switch>
          </div>
          {/* <div className="app-sidebar">
            <AppSideBar
              routes={routes}
              rootPath={match.path}
              currentProjectId={currentProjectId}
            />
          </div> */}
        </main>
        <AppFooter />
        {/* <Switch>
          <Route
            exact
            path={`${match.path}`}
            render={(props) => {
              //store idam search context
              if (window.location.search.startsWith('?code')) {
                window.sessionStorage.idamcode = window.location.search;
              }

              return <Redirect to={`${match.path}project`} />;
            }}
          />
        </Switch> */}
      </div>
    </>
  );
}

export default connector(checkLogin(AppContainer));
