import React, { memo } from 'react';

import { Radio } from 'antd';
const SingleSelection = (props: any) => {
  const { options = [], form, name, value } = props;

  const handleChange = (e: any) => {
    const v = e.target.value;
    if (form) {
      form.setFieldsValue({ [name]: v });
    }
    if (props.onChange) {
      props.onChange(v);
    }
  };

  return (
    <Radio.Group
      onChange={handleChange}
      value={value}
      className="radio-group"
      disabled={props.disabled || false}
    >
      {options.map((o: any, i: number) => (
        <Radio key={i} value={o.value}>
          {o.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default memo(SingleSelection);
