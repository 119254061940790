import React from 'react';
import { useHistory } from 'react-router-dom';
import '@styles/components/goBack.scss';

interface GoBackProps {
  label?: string;
}

function GoBack(props: GoBackProps) {
  const { label = 'Back' } = props;
  const history = useHistory();
  return (
    <span className="back-btn" onClick={history.goBack}>
      <span className="appkiticon icon-left-chevron-fill" />
      <span className="back-btn-text">{label}</span>
    </span>
  );
}

export default GoBack;
