import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import '@styles/components/breadcrumb.scss';

function itemRender(route: any, params: any, routes: any) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span className="breadcrumb-text last">{route.breadcrumbName}</span>
  ) : (
    <Link className="breadcrumb-text" to={route.path}>
      {route.breadcrumbName}
    </Link>
  );
}

function BreadCrumb(props: { data: Route[] }) {
  const routes = (props.data || []).filter((route) => route);

  return (
    <AntdBreadcrumb
      className="breadcrumb-wrapper"
      itemRender={itemRender}
      routes={routes}
    />
  );
}

export default memo(BreadCrumb);
