import React, { memo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import '@styles/pages/login.scss';
import { Loading } from '@components';
import { RootStoreType } from '@reducer';
import { loginAction } from '@action';
import { RouteComponentProps } from 'react-router';
import { isValidLogin } from '@utils';
import { LOGIN_TYPE } from '@models';
const { Button } = require('appkit-react');

const mapStoreToProps = (store: RootStoreType) => {
  const { login } = store;
  return {
    userInfo: login.userInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  login: (type: string) => loginAction.login(type),
});

const connector = connect(mapStoreToProps, mapDispatchToProps);

interface LoginPropsInterface
  extends RouteComponentProps,
    ConnectedProps<typeof connector> {}

function Login(props: LoginPropsInterface) {
  const { login, location, userInfo, history } = props;
  const [isloading, setIsloading] = useState<boolean>(false);
  const redirectToIdam = (type: string) => {
    const state: any = location.state;
    let from = '';
    if (state && state.from) {
      from = state.from;
    }
    if (isValidLogin() && userInfo && userInfo.uid) {
      const toRedirect =
        window.sessionStorage.getItem('redirectPath') || from || '/';
      history.replace(toRedirect);
      window.sessionStorage.removeItem('redirectPath');
      window.sessionStorage.removeItem('loginProgress');
    }

    window.sessionStorage.setItem('loginProgress', 'logged');
    setIsloading(true);
    if (login) {
      login(type);
    }
  };

  return (
    <div className="login-page-wrapper">
      {isloading ? (
        <Loading tip="login" isLoading={true} />
      ) : (
        <div className="login-page-content">
          <div className="title">M&A Gateway</div>
          <div className="text">
            Welcome to M&A Gateway. <br />
            Please select an option below to sign in.
          </div>
          <div className="button-column">
            <Button
              className="btn-wrapper"
              size="xl"
              onClick={() => redirectToIdam(LOGIN_TYPE.IAM)}
            >
              Client User
            </Button>
            <Button
              className="btn-wrapper"
              size="xl"
              kind="secondary"
              onClick={() => redirectToIdam(LOGIN_TYPE.IDENTITY)}
            >
              PwC User
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default connector(memo(Login));
