import React, { memo } from 'react';
import { withRouter } from 'react-router';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { GoBack } from '@components';

import Policy from './Policy';
import Cookies from './Cookies';
import Terms from './Terms';
import DisClaimer from './Disclaimer';

import '@styles/pages/privacyStatement/privacyStatement.scss';

const statements = [
  {
    title: 'Privacy Policy',
    key: 'policy',
    Content: Policy,
  },
  {
    title: 'Cookie Statement',
    key: 'cookie',
    Content: Cookies,
  },
  {
    title: 'Terms of Use',
    key: 'terms',
    Content: Terms,
  },
  {
    title: 'Project Disclaimer',
    key: 'disclaimer',
    Content: DisClaimer,
  },
];

const { TabPane } = Tabs;
const PrivacyStatement = (props: any) => {
  const history = useHistory();
  const tabsRender = () => (
    <Tabs
      type="card"
      size="large"
      tabBarStyle={{ borderBottom: '2px solid #FA5927' }}
      tabBarGutter={5}
    >
      {statements.map((s) => (
        <TabPane tab={s.title} key={s.key}>
          <s.Content history={history} />
        </TabPane>
      ))}
    </Tabs>
  );
  return (
    <>
      <div className="privacy-statement-container">
        <div className="inner-container">
          <GoBack />
          <div className="page-title-row section-row">
            <h1 className="page-title">Privacy Statement</h1>
          </div>
          <div className="tabs-container">{tabsRender()}</div>
        </div>
      </div>
    </>
  );
};

export default withRouter(memo(PrivacyStatement));
