import React, { useState, memo, useEffect } from 'react';
import { Select, Divider, Input } from 'antd';
import '@styles/components/selectCheckbox.scss';
import { Option } from '@models';

interface SelectCheckboxPropsInterface {
  options: Option[];
  selectedOption?: string;
  value?: string;
  onChange?: any;
  notCaseSensitive?: boolean;
  disabled?: boolean;
  name?: string;
  form?: any;
}

const SelectInput = (props: SelectCheckboxPropsInterface) => {
  const {
    options,
    selectedOption,
    value,
    onChange,
    notCaseSensitive = false,
    disabled = false,
  } = props;
  const [data, setData] = useState<any>([...options]);
  const [selectedValue, setSelectedValue] = useState(selectedOption);
  const [inputVisible, setInputVisible] = useState(true);
  const [values, setValue] = useState<any>('');
  const [hideMulSelectKey, setHideMulSelectKey] = useState<any>(0);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setData(options);
  }, [options]);

  const onInputFocus = () => {
    setInputVisible(true);
  };

  const onInputKeyDown = (event: any) => {
    event.stopPropagation && event.stopPropagation();
  };

  const onInputChange = (event: any) => {
    event.stopPropagation && event.stopPropagation();
    const { value } = event.target;
    if (value && value.trim() && /[^\w\s]/g.test(value.trim())) {
      return;
    }
    setValue(event.target.value);
  };

  const addItem = () => {
    const value: string = values.trim();
    if (!value) {
      return;
    }
    const existingData = data.find((item: Option) => {
      if (notCaseSensitive) {
        return item.value.toLowerCase() === value.toLowerCase();
      }
      return item.value === value;
    });

    if (!existingData) {
      setSelectedValue(value);
      setData([...data, { label: value, value }]);
      onChange && onChange(value);
    } else {
      setSelectedValue(existingData.value);
      onChange && onChange(existingData.value);
    }
    setValue('');
    setInputVisible(true);
    setHideMulSelectKey(hideMulSelectKey + 1);
  };

  const handleChange = (value: string) => {
    setSelectedValue(value);
    setHideMulSelectKey(hideMulSelectKey + 1);
    onChange && onChange(value);
  };

  const dropdownInputRender = (menu: any) => (
    <div className="dropdown">
      <div>{menu}</div>
      {inputVisible && (
        <div>
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              placeholder="Other"
              value={values}
              onFocus={onInputFocus}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              onPressEnter={addItem}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Select
      options={data}
      key={hideMulSelectKey}
      value={selectedValue}
      dropdownRender={dropdownInputRender}
      onChange={handleChange}
      disabled={disabled}
      getPopupContainer={(): any => document.querySelector('.ant-drawer-body')}
    />
  );
};

export default memo(SelectInput);
