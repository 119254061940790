import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import React from 'react';
import { loginAction } from '@action';
import { RootStoreType } from '@reducer';
import { Loading } from '@components';

const FullLoading = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading) {
    return (
      <div className="loading-wrapper-fullscreen">
        <Loading tip="loading..." />
      </div>
    );
  }
  return null;
};

const checkLogin = (CurrentComponent: any) => {
  function FinalComponent(props: any) {
    const pathname = props.history.location.pathname;
    const loginProgress = window.sessionStorage.getItem('loginProgress');
    const { isLogin } = props;
    const { ...params } = props;
    const isGettingCode = window.location.search.startsWith('?code');

    if (isGettingCode) {
      if (!isLogin && loginProgress) {
        props.getLogin(`${window.location.search}`).then((token: String) => {
          props.getUserInfoFromToken(token);
        });
      }
    }

    if (!isLogin && loginProgress && isGettingCode) {
      return <FullLoading isLoading={true} />;
    }
    if (pathname === '/login' && isLogin) {
      return <Redirect to="/" />;
    }
    if (pathname === '/login' && !isLogin) {
      window.sessionStorage.removeItem('loginProgress');
      return <CurrentComponent {...params} />;
    }
    if (pathname !== '/login' && !isLogin) {
      return <Redirect to="/login" />;
    }

    return <CurrentComponent {...params} />;
  }

  return connect(
    (store: RootStoreType) => ({
      isLogin: store.login.isLogin,
      userInfo: store.login.userInfo,
    }),
    {
      getLogin: loginAction.getLogin,
      getUserInfoFromToken: loginAction.getUserInfoFromToken,
    },
  )(FinalComponent);
};

export { checkLogin };
