import { handleActions, Action } from 'redux-actions';
import { produce } from 'immer';
import {
  OperationalizationReducerState,
  operationalizationActionsEnum,
} from '../types';
// import { ProjectEntanglement } from '@models';
// operationalization: any[];
// isOperationalizationLoading: boolean;
const defaultState: OperationalizationReducerState = {
  operationalizations: [],
  operationalizationsColumn: [],
  isOperationalizationLoading: true,
  isOperationalizationColumnLoading: true,
};

export default handleActions(
  {
    [operationalizationActionsEnum.GET_OPERATIONALIZATIONS_PENDING]: (
      state: OperationalizationReducerState,
    ) =>
      produce(state, (draft: OperationalizationReducerState) => {
        draft.isOperationalizationLoading = true;
      }),
    [operationalizationActionsEnum.GET_OPERATIONALIZATIONS_FULFILLED]: (
      state: OperationalizationReducerState,
      action: Action<any[]>,
    ) =>
      produce(state, (draft: OperationalizationReducerState) => {
        draft.isOperationalizationLoading = false;
        draft.operationalizations = action.payload || [];
      }),
    [operationalizationActionsEnum.GET_COLUMNOPERATIONALIZATIONS_PENDING]: (
      state: OperationalizationReducerState,
    ) =>
      produce(state, (draft: OperationalizationReducerState) => {
        draft.isOperationalizationColumnLoading = true;
      }),
    [operationalizationActionsEnum.GET_COLUMNOPERATIONALIZATIONS_FULFILLED]: (
      state: OperationalizationReducerState,
      action: Action<any[]>,
    ) =>
      produce(state, (draft: OperationalizationReducerState) => {
        draft.isOperationalizationColumnLoading = false;
        draft.operationalizationsColumn = action.payload || [];
      }),

    [operationalizationActionsEnum.CHANGE_OPERATIONALIZATIONS]: (
      state: OperationalizationReducerState,
      action: Action<any[]>,
    ) =>
      produce(state, (draft: OperationalizationReducerState) => {
        draft.operationalizations = action.payload || [];
      }),

    // [TsaActionsEnum.CHANGE_TSA_OPERATE_LOADING]: (
    //   state: TsaReducerState,
    //   action: Action<any>,
    // ) =>
    //   produce(state, (draft: EntanglementReducerState) => {
    //     draft.isEntanglementsOperateLoading = action.payload || false;
    //   }),
  },
  defaultState,
);
