import React from 'react';
import { ToggleConfig } from '@models';
import { Controller, useFormContext } from 'react-hook-form';
import '@styles/components/switchButton.scss';

const { Switch } = require('appkit-react');

interface SwitchButtonProps {
  name?: string;
  rules?: any;
  config?: ToggleConfig;
  toggleClick?: (value: boolean) => void;
}

function SwitchButton(props: SwitchButtonProps) {
  const { name, config, rules } = props;
  let switchOn = false;
  let switchPreText = '';
  let switchAfterText = '';
  const methods: any = useFormContext();
  if (config) {
    switchOn = Boolean(config.switchOn);
    switchPreText = config.preLabel;
    switchAfterText = config.afterLabel;
  }

  const onChange = (event: boolean) => {
    const { toggleClick } = props;
    if (name && methods) {
      setTimeout(() => {
        methods.setValue(name, event, true);
      }, 0);
    }
    if (toggleClick) {
      toggleClick(event);
    }
  };

  const switchButton = (
    <Switch
      className="switch-btn"
      defaultChecked={switchOn}
      onChange={(event: boolean) => {
        onChange(event);
      }}
      onKeyDown={(event: any) => {
        if (event.keyCode === 13) {
          event.target.blur();
        }
      }}
    />
  );
  return (
    <div className="flex-row-start switch-button">
      <span className="switch-btn-txt switch-pre-txt">{switchPreText}</span>
      {name && methods ? (
        <Controller
          as={switchButton}
          name={name || ''}
          control={methods.control}
          rules={rules || {}}
        />
      ) : (
        switchButton
      )}
      <span className="switch-btn-txt switch-after-txt">{switchAfterText}</span>
    </div>
  );
}

export default React.memo(SwitchButton);
