import { clearLocalStorage } from '@utils';
// import { UserInfo } from '@models';
import {
  getTokenFromCode,
  logoutRequest,
  getUserInfo,
  postUserTimeZoneInfo,
} from '@services';
import { createActions } from 'redux-actions';
import { LoginActionsEnum } from '../types';

const loginActions = createActions({}, LoginActionsEnum.SAVE_USER_INFO);

const getUserInfoFromToken = () => async (dispatch: any) => {
  const res = await getUserInfo();
  if (res && res.uid) {
    localStorage.setItem('userInfo', JSON.stringify(res));
    dispatch(loginActions.saveUserInfo(res));
  }
  postUserTimeZoneInfo(res?.email);
};

// TODO remove any, add types
const login = (type: string) => {
  type === 'identity'
    ? window.location.replace(
        `${process.env.REACT_APP_service_url}/auth/v1/login?sso=openam&state=${process.env.REACT_APP_state}&redirect=${process.env.REACT_APP_app_url}`,
      )
    : window.location.replace(
        `${process.env.REACT_APP_service_url}/auth/v1/login?sso=iam&state=${process.env.REACT_APP_IAM_RESPONSE_TYPE}&redirect=${process.env.REACT_APP_app_url}`,
      );
};

const getLogin = (codeStr: string) => async (dispatch: any) => {
  // const res = await validateIamTokenRequest(p);
  // if (res) {
  //   window.localStorage.setItem('accessToken', res.token);
  //   window.localStorage.setItem('idToken', res.token);
  // }
  // saveUserInfo(dispatch, res && res.userInfo);
  const res = await getTokenFromCode(codeStr);
  // if (!res || typeof res.token === 'undefined') {
  //   console.log(res);
  //   showRequestAccessModal(res);
  //   return;
  // }
  res && localStorage.setItem('accessToken', res?.token || '');
  res && localStorage.setItem('refreshToken', res?.refresh_token || '');
  return res?.token || '';
  // getUserInfo(dispatch);
};

const logout = () => async (dispatch: any) => {
  const res = await logoutRequest();
  if (res) {
    clearLocalStorage();
    dispatch(loginActions.saveUserInfo({}));
  }
};

const saveUserInfo = (user: any) => async (dispatch: any) => {
  dispatch(loginActions.saveUserInfo(user));
};

export const loginAction = {
  login,
  logout,
  getLogin,
  saveUserInfo,
  getUserInfoFromToken,
};
