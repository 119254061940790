import { combineReducers } from 'redux';
import login from './login.reducer';
import common from './common.reducer';
import project from './project.reducer';
import projectFunction from './function.reducer';
import entanglements from './entanglement.reducer';
import transitions from './transition.reducer';
import tsa from './tsa.reducer';
import operationalization from './operationalization.reducer';
import {
  LoginReducerState,
  CommonReducerState,
  ProjectReducerState,
  ProjectFunctionReducerState,
  EntanglementReducerState,
  TransitionReducerState,
  TsaReducerState,
  OperationalizationReducerState,
} from '../types';

export interface RootStoreType {
  login: LoginReducerState;
  common: CommonReducerState;
  project: ProjectReducerState;
  projectFunction: ProjectFunctionReducerState;
  entanglements: EntanglementReducerState;
  transitions: TransitionReducerState;
  tsa: TsaReducerState;
  operationalization: OperationalizationReducerState;
}

const reducers = combineReducers<RootStoreType>({
  login,
  common,
  project,
  projectFunction,
  entanglements,
  transitions,
  tsa,
  operationalization,
});

export default reducers;
